import React from 'react'
import {Form} from 'react-bootstrap'
import {formatPhone, formatAddress, parseActivities, cardStyle, cardBodyStyle} from './'

export const UserCard = ({data, title}) => (
  <div className='col-lg-6 col-md-6 col-sm-12'>
    <div className='card card-statistic-2' style={cardStyle}>
      <div className='card-header'>
        <h4>{title}</h4>
      </div>
      <div className='card-body' style={cardBodyStyle}>
        {data &&
          (Array.isArray(data) ? data : [data]).map((user) => (
            <div key={user.id || user.name} className='mt-2 d-flex flex-column'>
              <label className='fs-6'>Name</label>
              <p>{user.name}</p>
              <label className='fs-6'>Email</label>
              <p>{user.email}</p>
              <label className='fs-6'>Phone</label>
              <p>{formatPhone(user.phone)}</p>
              <label className='fs-6'>Address</label>
              <p>{formatAddress(user.address)}</p>
              <hr />
            </div>
          ))}
      </div>
    </div>
  </div>
)

export const DocumentPreview = ({name, document, handleShowModal}) => (
  <div className='col-lg-6 col-md-6 col-sm-12'>
    <div className='card card-statistic-2'>
      <div className='card-header mb-2'>
        <h4>Document Preview</h4>
      </div>
      <div className='card-body' style={cardBodyStyle}>
        {document && (
          <div className='mt-2'>
            <h6 className='card-title'>{document.name}</h6>
            <h6 className='card-title'>{document.author === name ? 'Sent by Me' : `Sent from ${document.author}`}</h6>
            <iframe
              src={`data:${document.mime_type};base64,${Buffer.from(document.content).toString('base64')}`}
              frameBorder='0'
              width='100%'
              height='300px'
              onClick={handleShowModal}
            ></iframe>
          </div>
        )}
      </div>
    </div>
  </div>
)

export const CheckInCard = ({handleCheckIn, event, location, error}) => (
  <div className='col-lg-6 col-md-6 col-sm-12'>
    {console.log(event)}
    <div className='card card-statistic-2' style={cardStyle}>
      <div className='card-header'>
        <h4>Check - In</h4>
      </div>
      <div className='card-body' style={cardBodyStyle}>
        {event && (
          <div className='mb-n2'>
            <label className='fs-6'>Name</label>
            <p>{event.name}</p>
            <div className='d-flex justify-content-start'>
              <div className='d-flex flex-column me-4'>
                <label className='fs-6'>Starts</label>
                <p>
                  {new Date(event.start_time).toLocaleString([], {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  })}
                </p>
              </div>
              <div className='d-flex flex-column'>
                <label className='fs-6'>Ends</label>
                <p>
                  {new Date(event.end_time).toLocaleString([], {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  })}
                </p>
              </div>
            </div>
          </div>
        )}
        {event?.location && (
          <div>
            <label className='fs-6'>Location</label>
            <p>{event.location.address}</p>
            <hr className='mt-2' />
            {event.invitees?.length > 0 && (
              <div className='d-flex justify-content-start mb-1'>
                <div className='d-flex flex-column me-5'>
                  {event.invitees[0].checkin_time && <label className='fs-6'>Check-In</label>}
                  <p>
                    {event.invitees[0].checkin_time &&
                      new Date(event.invitees[0].checkin_time).toLocaleString([], {
                        dateStyle: 'short',
                        timeStyle: 'short',
                      })}
                  </p>
                </div>
                <div className='d-flex flex-column'>
                  {event.invitees[0].checkout_time && <label className='fs-6'>Check-Out</label>}
                  <p>
                    {event.invitees[0].checkout_time &&
                      new Date(event.invitees[0].checkout_time).toLocaleString([], {
                        dateStyle: 'short',
                        timeStyle: 'short',
                      })}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
        {location?.latitude && location?.longitude ? (
          <div className='mt-2'>
            <p>success</p>
          </div>
        ) : error ? (
          <div className='mt-2'>
            <p>{error}</p>
          </div>
        ) : null}
      </div>
      <div className='card-footer'>
        <button
          className='btn btn-primary w-100'
          onClick={() => handleCheckIn(event?.invitees[0]?.checkin_time != null)}
          disabled={!!error || event?.invitees[0]?.checkout_time}
        >
          {event?.invitees[0]?.checkin_time && !event?.invitees[0]?.checkout_time ? 'check - out' : 'check - in'}
        </button>
      </div>
    </div>
  </div>
)

export const ActivitiesEditCard = ({
  handleSubmit,
  handleClear,
  info,
  tempRecord,
  handleInputChange,
  handleNurseClick,
  handleAddRecord,
  handleCheckboxChange,
}) => {
  return (
    <div className='col-lg-6 col-md-6 col-sm-12'>
      <div onSubmit={handleSubmit} className='card card-statistic-2' style={cardStyle}>
        <Form>
          <div className='card-header'>
            <div className='d-flex justify-content-between'>
              <h4>Activities Info</h4>
              {tempRecord && <i className='fas fa-times fs-6' type='button' onClick={handleClear} aria-label='Clear temporary record' />}
            </div>
          </div>
          <div className='card-body mb-3' style={cardBodyStyle}>
            {/* Feeding Section */}
            <Form.Group className='mt-2 mb-3'>
              <div className='d-flex justify-content-between align-items-center mb-n1'>
                <Form.Label className='fs-6'>Feeding</Form.Label>
                {info.feed.length > 0 && (
                  <p className='fs-6 mb-n1'>
                    +{info.feed.length} {info.feed.length === 1 ? 'Record' : 'Records'}
                  </p>
                )}
              </div>
              <div className='d-flex align-items-end'>
                <div className='me-2 flex-fill'>
                  <Form.Label className='fs-6'>Time</Form.Label>
                  <Form.Control
                    type='time'
                    value={tempRecord.feed?.time || ''}
                    onChange={(e) => handleInputChange('feed', 'time', e.target.value)}
                  />
                </div>
                <div className='me-2 flex-fill'>
                  <Form.Label className='fs-6'>Nurse</Form.Label>
                  <button
                    type='button'
                    onClick={handleNurseClick}
                    className='btn btn-secondary text-uppercase text-primary'
                    style={{
                      minHeight: '42px',
                      width: 'clamp(40px, 10vw, 50px)',
                      outlineColor: 'gray',
                    }}
                    aria-label={`Nurse side selected: ${tempRecord.feed?.nurse || 'None'}. Click to toggle.`}
                  >
                    {tempRecord.feed?.nurse || ' '}
                  </button>
                </div>
                <div className='me-2 flex-fill'>
                  <Form.Label className='fs-6'>Feed</Form.Label>
                  <Form.Control
                    type='number'
                    value={tempRecord.feed?.feedOz || ''}
                    min='0'
                    placeholder='oz/ml'
                    onChange={(e) => handleInputChange('feed', 'feedOz', e.target.value)}
                  />
                </div>
                <div className='me-2 flex-fill'>
                  <Form.Label className='fs-6'>Pump</Form.Label>
                  <Form.Control
                    type='number'
                    value={tempRecord.feed?.pumpOz || ''}
                    min='0'
                    placeholder='oz/ml'
                    onChange={(e) => handleInputChange('feed', 'pumpOz', e.target.value)}
                  />
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => handleAddRecord('feed')}
                    style={{
                      minHeight: '42px',
                    }}
                    aria-label='Add feeding record'
                  >
                    <i className='fas fa-plus'></i>
                  </button>
                </div>
              </div>
            </Form.Group>

            {/* Sleep Section */}
            <Form.Group className='mb-3'>
              <div className='d-flex justify-content-between align-items-center mb-n1'>
                <Form.Label className='fs-6'>Sleeping</Form.Label>
                {info.sleep.length > 0 && (
                  <p className='fs-6 mb-n1'>
                    +{info.sleep.length} {info.sleep.length === 1 ? 'Record' : 'Records'}
                  </p>
                )}
              </div>
              <div className='d-flex align-items-end'>
                <div className='me-2 flex-fill'>
                  <Form.Label className='fs-6'>Start</Form.Label>
                  <Form.Control
                    type='time'
                    value={tempRecord.sleep?.start || ''}
                    onChange={(e) => handleInputChange('sleep', 'start', e.target.value)}
                  />
                </div>
                <div className='me-2 flex-fill'>
                  <Form.Label className='fs-6'>End</Form.Label>
                  <Form.Control
                    type='time'
                    value={tempRecord.sleep?.end || ''}
                    onChange={(e) => handleInputChange('sleep', 'end', e.target.value)}
                  />
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => handleAddRecord('sleep')}
                    style={{
                      minHeight: '42px',
                    }}
                    aria-label='Add sleep record'
                  >
                    <i className='fas fa-plus'></i>
                  </button>
                </div>
              </div>
            </Form.Group>

            {/* Diapers Section */}
            <Form.Group className='mb-3'>
              <div className='d-flex justify-content-between align-items-center mb-n1'>
                <Form.Label className='fs-6'>Diapers</Form.Label>
                {info.diapers.length > 0 && (
                  <p className='fs-6 mb-n1'>
                    +{info.diapers.length} {info.diapers.length === 1 ? 'Record' : 'Records'}
                  </p>
                )}
              </div>
              <div className='d-flex align-items-end'>
                <div className='me-2 flex-fill'>
                  <Form.Label className='fs-6'>Time</Form.Label>
                  <Form.Control
                    type='time'
                    value={tempRecord.diapers?.time || ''}
                    onChange={(e) => handleInputChange('diapers', 'time', e.target.value)}
                  />
                </div>
                <div className='me-2 flex-fill'>
                  <Form.Check
                    type='checkbox'
                    label='Wet'
                    checked={tempRecord.diapers?.wet || false}
                    onChange={(e) => handleCheckboxChange('diapers', 'wet', e.target.checked)}
                  />
                </div>
                <div className='me-2 flex-fill'>
                  <Form.Check
                    type='checkbox'
                    label='Dirty'
                    checked={tempRecord.diapers?.dirty || false}
                    onChange={(e) => handleCheckboxChange('diapers', 'dirty', e.target.checked)}
                  />
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => handleAddRecord('diapers')}
                    style={{
                      minHeight: '42px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    aria-label='Add diaper record'
                  >
                    <i className='fas fa-plus'></i>
                  </button>
                </div>
              </div>
            </Form.Group>

            <button className='btn btn-primary w-100' type='submit'>
              Submit
            </button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export const ActivitiesInfoCard = ({data, title}) => {
  const parsedData = data ? parseActivities(data) : {}

  return (
    <div className='col-lg-6 col-md-6 col-sm-12'>
      <div className='card card-statistic-2' style={cardStyle}>
        <div className='card-header'>
          <h4>{title}</h4>
        </div>
        <div className='card-body' style={cardBodyStyle}>
          <div className='mt-2 d-flex flex-column'>
            {/* Feeding Section */}
            <label className='fs-6'>Feeding</label>
            {parsedData.feed && parsedData.feed.length > 0 ? (
              parsedData.feed.map((feed, index) => (
                <div key={index} className='d-flex justify-content-between mb-2'>
                  <div>
                    <label className='fs-6'>Time</label>
                    <p>{feed.time}</p>
                  </div>
                  {feed.nurse && (
                    <div>
                      <label className='fs-6'>Nurse</label>
                      <p>
                        {feed.nurse}
                        {feed.duration !== undefined ? ` ${feed.duration} min` : ''}
                      </p>
                    </div>
                  )}
                  <div>
                    <label className='fs-6'>Feed</label>
                    <p>{feed.feedOz !== undefined ? `${feed.feedOz} ml` : 'N/A'}</p>
                  </div>
                  <div>
                    <label className='fs-6'>Pump</label>
                    <p>{feed.pumpOz !== undefined ? `${feed.pumpOz} ml` : 'N/A'}</p>
                  </div>
                </div>
              ))
            ) : (
              <p className='text-muted'>No feeding data available.</p>
            )}
            <hr />

            {/* Sleeping Section */}
            <label className='fs-6'>Sleeping</label>
            {parsedData.sleep && parsedData.sleep.length > 0 ? (
              parsedData.sleep.map((sleep, index) => (
                <div key={index} className='d-flex justify-content-between mb-2'>
                  <div>
                    <label className='fs-6'>Start</label>
                    <p>{sleep.start}</p>
                  </div>
                  <div>
                    <label className='fs-6'>End</label>
                    <p>{sleep.end}</p>
                  </div>
                </div>
              ))
            ) : (
              <p className='text-muted'>No sleeping data available.</p>
            )}
            <hr />

            {/* Diapers Section */}
            <label className='fs-6'>Diapers</label>
            {parsedData.diapers && parsedData.diapers.length > 0 ? (
              parsedData.diapers.map((diaper, index) => (
                <div key={index} className='d-flex justify-content-between mb-2'>
                  <div>
                    <label className='fs-6'>Time</label>
                    <p>{diaper.time}</p>
                  </div>
                  {diaper.wet !== undefined && (
                    <div>
                      <label className='fs-6'>Wet</label>
                      <p>{diaper.wet ? 'Wet' : 'Dry'}</p>
                    </div>
                  )}
                  {diaper.dirty !== undefined && (
                    <div>
                      <label className='fs-6'>Dirty</label>
                      <p>{diaper.dirty ? 'Dirty' : 'Clean'}</p>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p className='text-muted'>No diaper data available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export const ClientReminders = () => {
  return (
    <div className='col-lg-6 col-md-6 col-sm-12'>
      <div className='card card-statistic-2' style={{cardStyle}}>
        <div className='card-header'>
          <h4>Reminders</h4>
        </div>
        <div className='card-body' style={{cardBodyStyle}}>
          <ul className='mt-2'>
            <li className='mt-2'>take vitamins</li>
            <li className='mt-2'>take medication</li>
            <li className='mt-2'>drink water</li>
            <li className='mt-2'>eat meals</li>
            <li className='mt-2'>eat snacks</li>
            <li className='mt-2'>shower</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
