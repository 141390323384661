import React, {Component} from "react"

class ToggleDropdown extends Component {
  state = {
    isOpen: false,
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }))
  }

  closeDropdown = () => {
    this.setState({isOpen: false})
  }

  renderContent = () => {
    const {data} = this.props
    return (
      <div className={`dropdown-list-content ${data.listClass}`}>
        {data.content.map((content, index) => (
          <a key={index} href='#' className={`dropdown-item ${content.unreadClass === true ? "dropdown-item-unread" : ""}`}>
            <div className={`dropdown-item-icon ${content.bgClass} text-white`}>
              <i className={content.iClassname} />
            </div>
            <div className='dropdown-item-desc'>
              {content.title ? <b>{content.title}</b> : null}
              <p>{content.description}</p>
              <div className={`time ${content.textCls ? content.textCls : ""}`}>{content.time}</div>
            </div>
          </a>
        ))}
      </div>
    )
  }

  render() {
    const {data} = this.props
    const {isOpen} = this.state

    return (
      <li className='dropdown dropdown-list-toggle mt-1'>
        <a href='#' className={`nav-link nav-link-lg ${data.toggleName} beep`} onClick={this.toggleDropdown}>
          <i className={data.iconName} />
        </a>
        {isOpen && (
          <div className={`dropdown-menu dropdown-list dropdown-menu-right show`}>
            <div className='dropdown-header'>
              {data.headerLeft}
              <div className='float-right'>
                <a href='#'>{data.headerRight}</a>
              </div>
            </div>
            {this.renderContent()}
            <div className='dropdown-footer text-center'>
              <a href='#'>
                {data.bottomMsg}
                <i className={data.bottomMsgCls} />
              </a>
            </div>
          </div>
        )}
        {isOpen && <div className='dropdown-backdrop' onClick={this.closeDropdown}></div>}
      </li>
    )
  }
}

export default ToggleDropdown
