import React, {useState} from 'react'
import {useHistory, withRouter} from 'react-router-dom'
import {registerUser} from '../../contexts/service'
import {validateEmail, validatePhone, validateAddress} from '../utils'

const Register = () => {
  const history = useHistory()
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phonePart1: '',
    phonePart2: '',
    phonePart3: '',
    address: {
      street: '',
      city: '',
      state: '',
      postalCode: '',
    },
    password: '',
    passwordConfirm: '',
    agree: false,
  })
  const [message, setMessage] = useState(null)

  const handleChange = (e) => {
    const {name, value, type, checked} = e.target

    if (name.includes('address.')) {
      const addressField = name.split('.')[1]
      setUserData({
        ...userData,
        address: {
          ...userData.address,
          [addressField]: value,
        },
      })
    } else {
      setUserData({
        ...userData,
        [name]: type === 'checkbox' ? checked : value,
      })

      if (value.length === e.target.maxLength) {
        switch (name) {
          case 'phonePart1':
            document.getElementsByName('phonePart2')[0].focus()
            break
          case 'phonePart2':
            document.getElementsByName('phonePart3')[0].focus()
            break
          default:
            break
        }
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const email = userData.email.toLowerCase().trim()
    const phone = `${userData.phonePart1}${userData.phonePart2}${userData.phonePart3}`
    console.log('Form submitted', userData)

    if (userData.password !== userData.passwordConfirm) {
      console.log('Passwords do not match')
      setMessage({type: 'error', text: 'Passwords do not match'})
      return
    }

    if (!validateEmail(userData.email)) {
      console.log('Invalid email format')
      setMessage({type: 'error', text: 'Invalid email format'})
      return
    }

    if (!validatePhone(phone)) {
      console.log('Invalid phone number')
      setMessage({type: 'error', text: 'Invalid phone number'})
      return
    }

    const addressError = validateAddress(userData.address)
    if (addressError) {
      console.log(addressError)
      setMessage({type: 'error', text: addressError})
      return
    }

    registerUser({
      name: userData.name,
      email: email,
      phone: phone,
      address: userData.address,
      password: userData.password,
    })
      .then((success) => {
        console.log('Registration response', success)

        if (success) {
          console.log('Registration successful')
          setMessage({type: 'success', text: 'Registration successful. Redirecting to login...'})
          setTimeout(() => {
            history.push('/login')
          }, 3000) // Redirect after 3 seconds
        } else {
          console.log('Registration failed')
          setMessage({type: 'error', text: 'Registration failed. Please try again.'})
        }
      })
      .catch((err) => {
        console.log('Registration error', err.message)
        setMessage({type: 'error', text: err.message})
      })
  }

  return (
    <div id='app'>
      <section className='section'>
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2'>
              <div className='login-brand'></div>

              <div className='card card-primary'>
                <div className='card-header'>
                  <h4>Register</h4>
                </div>

                <div className='card-body'>
                  {message && <div className={`alert alert-${message.type === 'error' ? 'danger' : 'success'}`}>{message.text}</div>}
                  <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                      <label htmlFor='name'>Name</label>
                      <input
                        id='name'
                        type='text'
                        className='form-control'
                        name='name'
                        value={userData.name}
                        onChange={handleChange}
                        required
                        autoFocus
                      />
                    </div>

                    <div className='form-group'>
                      <label htmlFor='email'>Email</label>
                      <input
                        id='email'
                        type='email'
                        className='form-control'
                        name='email'
                        value={userData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className='form-group'>
                      <label htmlFor='phone'>Phone</label>
                      <div className='form-row'>
                        <div className='col'>
                          <input
                            type='text'
                            className='form-control'
                            name='phonePart1'
                            value={userData.phonePart1}
                            onChange={handleChange}
                            maxLength={3}
                            required
                          />
                        </div>
                        <div className='col'>
                          <input
                            type='text'
                            className='form-control'
                            name='phonePart2'
                            value={userData.phonePart2}
                            onChange={handleChange}
                            maxLength={3}
                            required
                          />
                        </div>
                        <div className='col'>
                          <input
                            type='text'
                            className='form-control'
                            name='phonePart3'
                            value={userData.phonePart3}
                            onChange={handleChange}
                            maxLength={4}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className='form-row'>
                      <div className='form-group col-md-6'>
                        <label htmlFor='street'>Street</label>
                        <input
                          id='street'
                          type='text'
                          className='form-control'
                          name='address.street'
                          value={userData.address.street}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className='form-group col-md-6'>
                        <label htmlFor='city'>City</label>
                        <input
                          id='city'
                          type='text'
                          className='form-control'
                          name='address.city'
                          value={userData.address.city}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div className='form-row'>
                      <div className='form-group col-md-6'>
                        <label htmlFor='state'>State</label>
                        <input
                          id='state'
                          type='text'
                          className='form-control'
                          name='address.state'
                          value={userData.address.state}
                          onChange={handleChange}
                          maxLength={2}
                          required
                        />
                      </div>

                      <div className='form-group col-md-6'>
                        <label htmlFor='postalCode'>Postal Code</label>
                        <input
                          id='postalCode'
                          type='text'
                          className='form-control'
                          name='address.postalCode'
                          value={userData.address.postalCode}
                          onChange={handleChange}
                          maxLength={5}
                          required
                        />
                      </div>
                    </div>

                    <div className='form-group'>
                      <label htmlFor='password' className='d-block'>
                        Password
                      </label>
                      <input
                        id='password'
                        type='password'
                        className='form-control'
                        name='password'
                        value={userData.password}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className='form-group'>
                      <label htmlFor='password2' className='d-block'>
                        Password Confirmation
                      </label>
                      <input
                        id='password2'
                        type='password'
                        className='form-control'
                        name='passwordConfirm'
                        value={userData.passwordConfirm}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className='form-group'>
                      <div className='custom-control custom-checkbox'>
                        <input
                          type='checkbox'
                          name='agree'
                          className='custom-control-input'
                          id='agree'
                          checked={userData.agree}
                          onChange={handleChange}
                          required
                        />
                        <label className='custom-control-label' htmlFor='agree'>
                          I agree with the terms and conditions
                        </label>
                      </div>
                    </div>

                    <div className='form-group'>
                      <button type='submit' className='btn btn-primary btn-lg btn-block'>
                        Register
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default withRouter(Register)
