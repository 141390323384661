import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useAuth} from '../../contexts/authContext'
import {getRecipientsByUserId, getDocumentsByUserId} from '../../contexts/service'
import {cardStyle, cardBodyStyle, UserCard, ActivitiesInfoCard, DocumentPreview, ClientReminders} from '../utils'

const DashClient = () => {
  const {user} = useAuth()
  const [showModal, setShowModal] = useState(false)
  const [document, setDocument] = useState(null)
  const [recipients, setRecipients] = useState([])

  useEffect(() => {
    if (user) {
      fetchData()
    }
  }, [user])

  const fetchData = async () => {
    Promise.all([getRecipientsByUserId(user.id), getDocumentsByUserId(user.id)])
      .then(([recipientData, documentData]) => {
        if (recipientData) {
          const filteredRecipients = recipientData.filter((recipient) => recipient.name.toLowerCase() !== 'admin')
          setRecipients(filteredRecipients)
        }
        if (documentData) {
          setDocument(documentData[0])
        }
      })
      .catch((error) => {
        console.log('No data.')
      })
  }

  const dataStr = user?.name.includes('Allison')
    ? `feed: 08:00 PM L 10 0 0, 10:33 PM  40 0, 12:10 AM  35 0, 02:00 AM  40 0, 07:10 AM  40 0, 07:30 AM  40 0
sleep: 09:15 PM - 10:00 PM, 10:55 PM - 12:03 AM, 12:33 AM - 01:55 AM, 02:35 AM - 04:20 AM, 04:58 AM - 07:10 AM
diapers: 10:30 PM true true, 12:03 AM true false, 01:55 AM true true, 07:10 AM true true`
    : ''

  const handleShowModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='row'>
          <UserCard data={recipients} title='Assigned Care Info' />
          <ActivitiesInfoCard data={dataStr} title='Activities Info' />
        </div>
        <div className='row'>
          <DocumentPreview document={document} handleShowModal={handleShowModal} />
          <ClientReminders />
        </div>
      </section>
      <div>
        <canvas height='400'></canvas>
      </div>

      {document && (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{document.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {document.mime_type === 'application/pdf' ? (
              <iframe src={`data:application/pdf;base64,${btoa(document.content)}`} width='100%' height='500px'></iframe>
            ) : (
              <pre>{document.content}</pre>
            )}
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default DashClient
