const RRULES = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
}

const WEEKDAYS = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']

export const parseRRule = (rruleString) => {
  if (!rruleString) {
    return {}
  }

  const rules = rruleString.split(';')
  const ruleObj = {}

  rules.forEach((rule) => {
    const [key, value] = rule.split('=')
    ruleObj[key] = value.includes(',') ? value.split(',') : value
  })

  return ruleObj
}

export const formatDate = (dateStr) => {
  if (!dateStr) {
    return null
  }

  if (!dateStr.includes('-') && !dateStr.includes(':')) {
    const year = dateStr.substring(0, 4)
    const month = dateStr.substring(4, 6)
    const day = dateStr.substring(6, 8)
    const hour = dateStr.substring(9, 11)
    const minute = dateStr.substring(11, 13)
    const second = dateStr.substring(13, 15)
    if (!year || !month || !day || !hour || !minute || !second) {
      return null
    }
    return `${year}-${month}-${day}T${hour}:${minute}:${second}Z`
  }
  return dateStr
}

export const generateOccurrencesWithExdates = (rruleString, startDate) => {
  const rrule = parseRRule(rruleString)
  const occurrences = []
  let currentDate = new Date(startDate)
  const formattedUntil = formatDate(rrule.UNTIL)
  const endDate = rrule.UNTIL ? new Date(formattedUntil) : null
  const interval = parseInt(rrule.INTERVAL) || 1
  const exdates = rrule.EXDATE ? (Array.isArray(rrule.EXDATE) ? rrule.EXDATE : [rrule.EXDATE]) : []

  // Parse exdates and format them for comparison
  const exdateSet = new Set(
    exdates
      .map(formatDate)
      .filter(Boolean) // Filter out any null values from invalid EXDATEs
      .map((date) => {
        const parsedDate = new Date(date)
        return isNaN(parsedDate) ? null : parsedDate.toISOString().split('T')[0]
      })
      .filter(Boolean)
  )

  if (rrule.UNTIL && (!endDate || isNaN(endDate))) {
    return []
  }

  if (!rrule.FREQ) {
    return []
  }

  while (!rrule.UNTIL || currentDate <= endDate) {
    const currentDateString = currentDate.toISOString().split('T')[0]

    if (!exdateSet.has(currentDateString)) {
      occurrences.push(new Date(currentDate))
    }

    switch (rrule.FREQ) {
      case RRULES.DAILY:
        currentDate.setDate(currentDate.getDate() + interval)
        break
      case RRULES.WEEKLY:
        const days = Array.isArray(rrule.BYDAY) ? rrule.BYDAY.map((day) => WEEKDAYS.indexOf(day)) : []
        const nextDay = days.find((day) => day > currentDate.getDay())
        const daysToAdd = nextDay !== undefined ? nextDay - currentDate.getDay() : 7 - currentDate.getDay() + (days[0] || 0)
        currentDate.setDate(currentDate.getDate() + daysToAdd)
        break
      case RRULES.MONTHLY:
        currentDate.setMonth(currentDate.getMonth() + interval)
        break
      case RRULES.YEARLY:
        currentDate.setFullYear(currentDate.getFullYear() + interval)
        break
      default:
        throw new Error(`Unsupported frequency: ${rrule.FREQ}`)
    }
  }

  return occurrences
}

// Function to add EXDATE for all future occurrences
export const addExdatesForFutureOccurrences = (event, baseId, events) => {
  const futureOccurrences = events
    .filter((e) => e.id.startsWith(baseId) && new Date(e.start_time) > new Date(event.start_time))
    .map(
      (e) =>
        new Date(e.start_time)
          .toISOString()
          .replace(/[-:]/g, '')
          .split('.')[0] + 'Z'
    )

  return futureOccurrences
}

// Function to check if an event is the first occurrence
export const isFirstOccurrence = (event, events) => {
  if (!event?.id) {
    return false
  }
  const baseId = event.id
    .split('-')
    .slice(0, 5)
    .join('-')
  const occurrences = events.filter((e) => e.id.startsWith(baseId))
  const firstOccurrence = occurrences.reduce((earliest, current) => {
    return new Date(current.start_time) < new Date(earliest.start_time) ? current : earliest
  })
  return event.id === firstOccurrence.id
}
