export const handleError = (setError, error, defaultMessage = 'An unexpected error occurred.') => {
  const status = error?.status || error?.response?.status
  const message = error?.message || error?.response?.data?.message || defaultMessage

  if (process.env.NODE_ENV === 'development') {
    console.error(error, error.stack ? error.stack : '')
  } else {
    console.error('Error')
  }

  setError(status !== 404 ? message: null)
}
