import React, {useEffect, useState} from 'react'
import {fetchSignups, deleteSignups} from '../../contexts/service'
import {cardBodyStyle, handleRowSelect, handleMouseDown, handleMouseUp, handleMouseEnter} from '../utils'

const SignupData = () => {
  const [entries, setEntries] = useState([])

  const [selectedEntries, setSelectedEntries] = useState([])
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    fetchSignups(null, setEntries)
  }, [])

  const handleDeleteSelected = async () => {
    const itemIds = selectedEntries.map((item) => item.id)
    const confirmDelete = window.confirm('Are you sure you want to delete the selected entries?')
    if (confirmDelete) {
      deleteSignups(itemIds).then(() => {
        setSelectedEntries([])
        fetchSignups(null, setEntries)
      })
    }
  }

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-header'>
                <h4>Signups</h4>
              </div>
              <div className='input-group-btn d-flex justify-content-end align-items-center mt-2 mb-0'>
                {selectedEntries.length > 0 && <h6 className='mt-1 mr-3 mb-0'>{selectedEntries.length} selected</h6>}
                {selectedEntries.length > 0 && (
                  <i className='fas fa-times text-danger mt-0 mr-3' style={{fontSize: '1.5rem'}} onClick={handleDeleteSelected}></i>
                )}
              </div>
              <div className='card-body' style={cardBodyStyle}>
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th scope='col'>ID</th>
                      <th scope='col'>First Name</th>
                      <th scope='col'>Last Name</th>
                      <th scope='col'>Gender</th>
                      <th scope='col'>Email</th>
                      <th scope='col'>Phone</th>
                      <th scope='col'>Address</th>
                      <th scope='col'>City</th>
                      <th scope='col'>State</th>
                      <th scope='col'>Zip</th>
                      <th scope='col'>Country</th>
                      <th scope='col'>Occupation</th>
                      <th scope='col'>Attended</th>
                      <th scope='col'>Experience</th>
                      <th scope='col'>Referral</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entries.map((entry) => (
                      <tr
                        key={entry.id}
                        onClick={() => handleRowSelect(entry, selectedEntries, setSelectedEntries)}
                        onMouseDown={() => handleMouseDown(setIsDragging)}
                        onMouseUp={() => handleMouseUp(setIsDragging)}
                        onMouseEnter={() => handleMouseEnter(entry, isDragging, selectedEntries, setSelectedEntries)}
                        className={selectedEntries.includes(entry) ? 'table-secondary' : ''}
                      >
                        <td>{entry.id}</td>
                        <td>{entry['24.3']}</td>
                        <td>{entry['24.6']}</td>
                        <td>{entry['13.2']}</td>
                        <td>{entry['26']}</td>
                        <td>{entry['25']}</td>
                        <td>{entry['27.1']}</td>
                        <td>{entry['27.3']}</td>
                        <td>{entry['27.4']}</td>
                        <td>{entry['27.5']}</td>
                        <td>{entry['27.6']}</td>
                        <td>{entry['18']}</td>
                        <td>{entry['19.2']}</td>
                        <td>{entry['20.1']}</td>
                        <td>{entry['22.4']}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SignupData
