import React, {useState, useEffect, useCallback} from 'react'
import {Modal, Dropdown} from 'react-bootstrap'
import {jsPDF} from 'jspdf'
import $ from 'jquery'
import 'summernote/dist/summernote-bs4.css'
import 'summernote/dist/summernote-bs4.js'
import {useAuth} from '../contexts/authContext.js'
import {getRecipientsByUserId, getDocumentsByUserId, saveDocument, addRelated} from '../contexts/service.js'
import {truncateText, handleError} from './utils'

const Editor = () => {
  const {user} = useAuth()
  const [recipients, setRecipients] = useState([])
  const [selectedRecipient, setSelectedRecipient] = useState('')
  const [documentData, setDocumentData] = useState({
    content: '',
  })
  const [documents, setDocuments] = useState([])
  const [modalState, setModalState] = useState({
    show: false,
    type: '',
    content: null,
  })
  const [error, setError] = useState(null)

  // Load recipients when user changes
  useEffect(() => {
    const loadRecipients = async () => {
      if (user && user.id) {
        const recipientData = await getRecipientsByUserId(user.id).catch((err) => handleError(setError, err))
        if (recipientData) {
          setRecipients(recipientData)
        }
      }
    }

    loadRecipients()
  }, [user])

  // Initialize Summernote and load recent documents on component mount
  useEffect(() => {
    const initializeEditor = async () => {
      const savedDocumentData = localStorage.getItem('documentData')
      if (savedDocumentData) {
        const parsedData = JSON.parse(savedDocumentData)
        setDocumentData(parsedData)
        initializeSummernote(parsedData.content)
      } else {
        initializeSummernote('')
      }

      await loadRecentDocuments()
    }

    initializeEditor()

    return () => {
      const currentContent = $('#summernote').summernote('code')
      const newDocumentData = {...documentData, content: currentContent}
      updateDocumentData(newDocumentData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fetch recent documents and related documents
  const loadRecentDocuments = useCallback(async () => {
    if (user && user.id) {
      const data = await getDocumentsByUserId(user.id).catch((err) => handleError(setError, err))
      if (data) {
        const combined = [...(data.documents || []), ...(data.related || [])]

        const filteredDocuments = combined
          .filter((doc) => {
            const lowerName = doc.name.toLowerCase()
            return user.group === 'client' ? lowerName.includes('request') : lowerName.includes('report')
          })
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .slice(0, 10)

        setDocuments(filteredDocuments)
        console.log('Fetched Documents:', filteredDocuments)
      }
    }
  }, [user])

  // Filter out admin from recipients
  const filteredRecipients = recipients.filter((recipient) => recipient.name.toLowerCase() !== 'admin')

  // Initialize Summernote editor with content and handle changes
  const initializeSummernote = (content) => {
    $('#summernote')
      .summernote({
        height: 380,
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          ['para', ['paragraph']],
        ],
        callbacks: {
          onChange: function(contents) {
            const plainText = $('<div>')
              .html(contents)
              .text()
            const newDocumentData = {...documentData, content: plainText}
            updateDocumentData(newDocumentData)
          },
        },
      })
      .summernote('code', content)
  }

  // Update document data state and localStorage
  const updateDocumentData = (newData) => {
    setDocumentData(newData)
    localStorage.setItem('documentData', JSON.stringify(newData))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const pdfBlob = convertToPDF(documentData)
    if (!pdfBlob) {
      handleError(setError, new Error('PDF conversion failed'))
      return
    }

    const formData = new FormData()
    const localDate = new Date().toLocaleDateString('en-CA') // 'en-CA' gives format YYYY-MM-DD
    const fileName = user.group === 'client' ? `Request ${localDate}.pdf` : `Report ${localDate}.pdf`
    formData.append('content', pdfBlob, fileName)
    formData.append('name', fileName)
    formData.append('author', user.name)

    // Save the document for the current user
    const saveResult = await saveDocument(formData, user.id).catch((err) => handleError(setError, err))
    if (!saveResult || !saveResult.id) {
      handleError(setError, new Error('Document saving failed or ID is missing'))
      return
    }

    const savedDocumentId = saveResult.id

    // Prepare related items to share with selectedRecipient and admin
    const relatedItems = []
    if (selectedRecipient) {
      relatedItems.push({
        userid: selectedRecipient,
        relatedIds: [savedDocumentId],
        relationship_type: 'document',
      })
    }
    const admin = recipients.find((recipient) => recipient.group === 'admin')
    if (admin?.id && user.group !== 'admin') {
      relatedItems.push({
        userid: admin.id,
        relatedIds: [savedDocumentId],
        relationship_type: 'document',
      })
      console.log('Admin added to relatedItems.')
    } else if (user.group === 'admin') {
      console.log('User is admin; admin not added to relatedItems.')
    }

    // Log the prepared related items
    console.log('Related Items to Share:', relatedItems)

    // Share the document by adding related items
    if (relatedItems.length > 0) {
      await addRelated(relatedItems, user.id).catch((err) => handleError(setError, err))
    }

    // Reset the form after successful submission
    resetForm()
    await loadRecentDocuments()
  }

  // Convert document content to PDF blob
  const convertToPDF = (data) => {
    const doc = new jsPDF()
    doc.setFontSize(12)

    const pageWidth = doc.internal.pageSize.getWidth()
    const margin = 10
    const maxLineWidth = pageWidth - margin * 2

    const contentLines = doc.splitTextToSize(data.content, maxLineWidth)
    doc.text(contentLines, margin, 30)

    return doc.output('blob')
  }

  // Reset the form to initial state
  const resetForm = () => {
    setDocumentData({content: ''})
    setSelectedRecipient('')
    $('#summernote').summernote('reset')
    localStorage.removeItem('documentData')
  }

  // Handle document click to view in modal
  const handleDocClick = (document) => {
    setModalState({
      show: true,
      type: 'view',
      content: document,
    })
  }

  // Close the modal
  const handleCloseModal = () => {
    setModalState({
      show: false,
      content: null,
    })
  }

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='section-header'>
          <h1>{user.group === 'client' ? 'Requests' : 'Reports'}</h1>
        </div>
        <div className='section-body'>
          <div className='row'>
            {/* Error Alert */}
            {error && (
              <div className='col-12'>
                <div className='alert alert-danger' role='alert'>
                  {error}
                </div>
              </div>
            )}

            {/* Editor Card */}
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-10 col-md-10 col-sm-12'>
                      <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                          <label>Recipient</label>
                          <div className='d-flex align-items-center'>
                            <div className='flex-grow-1'>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant='primary'
                                  id='dropdown-basic'
                                  className='full-width-dropdown'
                                  style={{fontSize: '12.5px'}}
                                >
                                  {selectedRecipient
                                    ? recipients.find((r) => r.id === selectedRecipient)?.name || 'Select a recipient'
                                    : 'Select a recipient'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='full-width-dropdown text-center'>
                                  {filteredRecipients.map((recipient) => (
                                    <Dropdown.Item
                                      key={recipient.id}
                                      onClick={() => setSelectedRecipient(recipient.id)}
                                      style={{fontSize: '12.5px'}}
                                    >
                                      {recipient.name}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <button type='submit' className='btn btn-primary ml-3 d-flex align-items-center'>
                              <div className='mx-n0 mr-1' style={{fontSize: '12.5px'}}>
                                Send
                              </div>
                              <i className='fas fa-arrow-up' />
                            </button>
                          </div>
                        </div>
                        <div className='form-group'>
                          <label>Content</label>
                          <div id='summernote' className='summernote-simple form-control'></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Recent Documents Card */}
            <div className='col-12'>
              <div className='card'>
                <div className='card-header'>
                  <h4>Recents</h4>
                </div>
                <div className='card-body' style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
                  {documents.map((document, index) => (
                    <div
                      key={index}
                      className='mr-3'
                      style={{display: 'inline-block', cursor: 'pointer'}}
                      onDoubleClick={() => handleDocClick(document)}
                    >
                      <div className='card justify-content-center align-items-center' style={{width: '200px'}}>
                        <h6 className='card-title'>{document.name}</h6>
                        <h6 className='card-title mb-n1 d-flex justify-content-start'>
                          {document.author === user.name ? 'Sent by Me' : `Sent from ${truncateText(document.author)}`}
                        </h6>
                        <div className='card-body' style={{height: '200px', overflow: 'auto'}}>
                          <iframe
                            src={`data:${document.mime_type};base64,${document.content}`}
                            frameBorder='0'
                            width='100%'
                            height='200px'
                            title={document.name} // Added for accessibility
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Document View Modal */}
      {modalState.show && modalState.content && modalState.type === 'view' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>{modalState.content.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              src={`data:${modalState.content.mime_type};base64,${modalState.content.content}`}
              frameBorder='0'
              width='100%'
              height='600px'
              title={modalState.content.name} // Added for accessibility
            ></iframe>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default Editor
