import React, {useEffect, useRef, useState, useCallback} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid/index.js'
import interactionPlugin from '@fullcalendar/interaction/index.js'
import {Modal, Button, Form, Dropdown, ListGroup} from 'react-bootstrap'
import {useAuth} from '../contexts/authContext'
import {
  getEventsByUserId,
  createEvent,
  updateEvent,
  deleteEvents,
  addRelated,
  deleteRelated,
  getRecipientsByUserId,
  getRelatedEventsByUserId,
  fetchLocationPosition,
} from '../contexts/service'
import {
  cardBodyStyle,
  handleRowSelect,
  handleMouseDown,
  handleMouseUp,
  handleMouseEnter,
  daysOfWeek,
  convertUTCToLocalTime,
  parseAddressSugg,
  splitAddress,
  formatDateWithTime,
  formatDateForInput,
  formatTimeForInput,
  handleError,
  getFilteredData,
  SearchBar,
  parseRRule,
  formatDate,
  generateOccurrencesWithExdates,
  addExdatesForFutureOccurrences,
  isFirstOccurrence,
  CalendarModal,
} from './utils'
import {Map, Marker} from 'pigeon-maps'
import debounce from 'lodash.debounce'

const Calendar = () => {
  const {user} = useAuth()

  // Consolidated state
  const [state, setState] = useState({
    events: [],
    hoveredDate: null,
    selectedEvents: [],
    isDragging: false,
    searchTerm: '',
    shareSearch: '',
    recipients: [],
    selectedRecipients: [],
    modalState: {
      show: false,
      type: '',
      isEditing: false,
      location: false,
      markerPosition: [],
      suggestions: [],
      content: {
        id: null,
        name: '',
        details: '',
        start_time: '12:00',
        end_time: '13:00',
        start_date: new Date(),
        end_date: new Date(),
        rrule: '',
        location: {
          position: null,
          address: '',
        },
        invitees: [],
      },

      deletionMode: '',
      updateMode: '',
    },
    activeFilter: null,
    error: null,
  })

  const calendarRef = useRef(null)
  const originalEventRef = useRef(null)

  // Destructure state for easier access
  const {
    events,
    hoveredDate,
    selectedEvents,
    isDragging,
    searchTerm,
    shareSearch,
    recipients,
    selectedRecipients,
    modalState,
    activeFilter,
    error,
  } = state

  // Function to load events and recipients
  const loadEvents = () => {
    getEventsByUserId(user.id)
      .then((result) => {
        const combinedEvents = [...(result.events || []), ...(result.related || [])]
        const processedEvents = combinedEvents.map((event) => ({
          ...event,
          location: event.location || {address: '', position: []},
        }))
        const expandedEvents = expandRecurringEvents(processedEvents).sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
        setState((prev) => ({
          ...prev,
          events: expandedEvents,
        }))
      })
      .catch((err) => {
        handleError(setState, err, 'Failed to load events.')
      })
  }

  const loadRecipients = () => {
    getRecipientsByUserId(user.id)
      .then((recipientsResult) => {
        if (recipientsResult.length > 0) {
          setState((prev) => ({
            ...prev,
            recipients: recipientsResult,
          }))
        }
      })
      .catch((err) => {
        handleError(setState, err, 'Failed to load recipients.')
      })
  }

  useEffect(() => {
    loadEvents()
    loadRecipients()
  }, [user.id])

  // Expand recurring events
  const expandRecurringEvents = (events) =>
    events.flatMap((event) => {
      if (!event.rrule) return {...event}
      const occurrences = generateOccurrencesWithExdates(event.rrule, new Date(event.start_time))
      const duration = new Date(event.end_time) - new Date(event.start_time)
      return occurrences.map((date) => ({
        ...event,
        id: `${event.id}-${date.toISOString()}`,
        start_time: date,
        end_time: new Date(date.getTime() + duration),
      }))
    })

  // Get current view date range
  const getViewDateRange = () => {
    const calendarApi = calendarRef.current.getApi()
    const view = calendarApi.view
    return {start: view.activeStart, end: view.activeEnd}
  }

  // Handle deletion of selected events
  const handleDeleteSelected = (mode) => {
    const deletePromises = selectedEvents.map((event) => {
      if (event.rrule) {
        const baseId = event.id
          .split('-')
          .slice(0, 5)
          .join('-')
        if (mode === 'all') {
          if (isFirstOccurrence(event, events)) {
            return deleteEvents([baseId], user.id)
          }
          const futureExdates = addExdatesForFutureOccurrences(event, baseId, events)
          const selectedExdate =
            new Date(event.start_time)
              .toISOString()
              .replace(/[-:]/g, '')
              .split('.')[0] + 'Z'
          const allExdates = [...futureExdates, selectedExdate]
          const rruleObj = parseRRule(event.rrule)
          const updatedRRule = {
            ...rruleObj,
            EXDATE: [...(rruleObj.EXDATE || []), ...allExdates].map((date) => (/^\d{8}T\d{6}Z$/.test(date) ? date : formatDate(date))),
          }
          const newRRuleString = Object.entries(updatedRRule)
            .map(([key, value]) => `${key}=${Array.isArray(value) ? value.join(',') : value}`)
            .join(';')
          return updateEvent({id: baseId, rrule: newRRuleString}, user.id)
        }
        const exDate =
          new Date(event.start_time)
            .toISOString()
            .replace(/[-:]/g, '')
            .split('.')[0] + 'Z'
        const rruleObj = parseRRule(event.rrule)
        const updatedRRule = {
          ...rruleObj,
          EXDATE: [...(rruleObj.EXDATE || []), exDate].filter((date) => /^\d{8}T\d{6}Z$/.test(date)),
        }
        const newRRuleString = Object.entries(updatedRRule)
          .map(([key, value]) => `${key}=${Array.isArray(value) ? value.join(',') : value}`)
          .join(';')
        return updateEvent({id: baseId, rrule: newRRuleString}, user.id)
      }
      return deleteEvents([event.id], user.id)
    })

    Promise.all(deletePromises)
      .then(() => {
        handleCloseModal()
        setState((prev) => ({...prev, selectedEvents: []}))
        loadEvents()
      })
      .catch((err) => {
        handleError(setState, err, 'Error during event deletion.')
      })
  }

  // Handle updating all occurrences of an event
  const handleUpdateAllOccurrences = (newEvent, userId) => {
    if (!newEvent.rrule) {
      console.error('rrule is undefined or null')
      return Promise.resolve()
    }

    newEvent.location = modalState.content.location || null

    const {FREQ, INTERVAL, UNTIL} = parseRRule(newEvent.rrule)

    let updatedRRule = `FREQ=${FREQ};INTERVAL=${INTERVAL}`
    if (UNTIL) {
      updatedRRule += `;UNTIL=${UNTIL}`
    }
    if (FREQ === 'WEEKLY' && modalState.content.rruleObj.BYDAY && modalState.content.rruleObj.BYDAY.length > 0) {
      updatedRRule += `;BYDAY=${modalState.content.rruleObj.BYDAY.join(',')}`
    }

    newEvent.rrule = updatedRRule

    return updateEvent(newEvent, userId)
  }

  // Handle updating one occurrence of an event
  const handleUpdateOneOccurrence = (newEvent, userId) => {
    if (!newEvent.rrule) {
      console.error('rrule is undefined or null')
      return Promise.resolve()
    }

    const exDate =
      new Date(modalState.startDate)
        .toISOString()
        .replace(/[-:]/g, '')
        .split('.')[0] + 'Z'

    const rruleObj = parseRRule(newEvent.rrule)
    rruleObj.EXDATE = [...(rruleObj.EXDATE || []), exDate].filter((date) => /^\d{8}T\d{6}Z$/.test(date))

    const rruleString = Object.entries(rruleObj)
      .filter(([key, val]) => key !== 'BYDAY' || (Array.isArray(val) && val.length > 0))
      .map(([key, val]) => `${key}=${Array.isArray(val) ? val.join(',') : val}`)
      .join(';')

    const updatePromise = updateEvent({id: newEvent.id, rrule: rruleString}, userId)

    const createPromise = createEvent({...newEvent, rrule: ''}, userId)

    return Promise.all([updatePromise, createPromise])
  }

  // Handle updating selected events based on mode
  const handleUpdateSelected = (mode) => {
    const baseId = modalState.content.id
      .split('-')
      .slice(0, 5)
      .join('-')
    const newEvent = {
      id: baseId,
      name: modalState.content.name,
      start_time: formatDateWithTime(modalState.content.start_date, modalState.content.start_time),
      end_time: formatDateWithTime(modalState.content.end_date, modalState.content.end_time),
      description: modalState.content.details,
      location: modalState.content.location || null,
      rrule: modalState.content.rrule,
      invitees: modalState.content.invitees || [],
    }

    let updatePromise

    if (modalState.updateMode === 'one') {
      updatePromise = handleUpdateOneOccurrence(newEvent, user.id)
    } else if (modalState.updateMode === 'all') {
      updatePromise = handleUpdateAllOccurrences(newEvent, user.id)
    } else {
      updatePromise = Promise.resolve()
    }

    updatePromise
      .then(() => {
        handleCloseModal()
        loadEvents()
      })
      .catch((err) => {
        handleError(setState, err, 'Error updating events.')
      })
  }

  // Handle sharing selected events
  const handleShare = () => {
    const items = selectedRecipients.map((recipient) => ({
      userid: recipient,
      relatedIds: selectedEvents.map((event) => event.id),
      relationship_type: 'event',
    }))

    addRelated(items, user.id)
      .then(() => {
        handleCloseModal()
        setState((prev) => ({...prev, selectedEvents: []}))
        loadEvents()
      })
      .catch((err) => {
        handleError(setState, err, 'Error sharing events.')
      })
  }

  // Handle submitting the event form
  const handleSubmit = (e) => {
    e.preventDefault()
    const {isEditing, content, location: isLocationEnabled} = modalState

    // Handle recurring event updates separately
    if (isEditing && !isFirstOccurrence(selectedEvents[0], events) && content.rrule) {
      return handleUpdateClick()
    }

    // Parse start and end times
    const startDateTime = formatDateWithTime(content.start_date, content.start_time)
    const endDateTime = formatDateWithTime(content.end_date, content.end_time)

    // Validate time range
    if (startDateTime > endDateTime) {
      handleError(setState, new Error('Start time cannot be after end time'), 'Invalid time range.')
      return
    }

    // Reference to the original event for comparison
    const originalEvent = originalEventRef.current

    // Initialize an empty updates object
    const updates = {}

    // Define simple fields to compare
    const simpleFields = {
      name: content.name,
      description: content.details,
      start_time: startDateTime.toISOString(),
      end_time: endDateTime.toISOString(),
      rrule: content.rrule,
    }

    // Compare simple fields and populate updates
    Object.entries(simpleFields).forEach(([key, value]) => {
      if (value !== originalEvent[key]) {
        updates[key] = value
      }
    })

    // Compare 'location' field
    const locationChanged =
      content.location?.address !== originalEvent.location?.address ||
      JSON.stringify(content.location?.position) !== JSON.stringify(originalEvent.location?.position)

    if (locationChanged) {
      updates.location = content.location && (content.location.address || content.location.position) ? {...content.location} : null
    }

    // Compare 'invitees' field
    const inviteesChanged = JSON.stringify(content.invitees) !== JSON.stringify(originalEvent.invitees)
    if (inviteesChanged) {
      updates.invitees = content.invitees.map((invitee) => ({
        user_id: invitee.user_id,
        user: invitee.user,
        deleted: invitee.deleted || false,
        // Include additional fields if necessary
        checkin_time: invitee.checkin_time ? new Date(invitee.checkin_time).toISOString() : null,
        checkout_time: invitee.checkout_time ? new Date(invitee.checkout_time).toISOString() : null,
      }))
    }

    console.log('Updates to Submit:', updates) // Debugging

    // If no fields have changed, do not proceed
    if (Object.keys(updates).length === 0) {
      console.log('No changes detected. Skipping update.')
      handleCloseModal()
      return
    }

    // Construct the eventDetails with only changed fields
    const eventDetails = {
      id: content.id,
      ...updates,
    }

    // Function to submit the event
    const submitEvent = () => {
      console.log('Submitting Event Details:', eventDetails) // Debugging
      const eventPromise = isEditing ? updateEvent(eventDetails, user.id) : createEvent(eventDetails, user.id)

      eventPromise
        .then(() => {
          handleCloseModal()
          setState((prev) => ({...prev, selectedEvents: []}))
          loadEvents()
        })
        .catch((err) => {
          handleError(setState, err, 'Error submitting event.')
        })
    }

    // Handle location position fetching if necessary
    if (isLocationEnabled && content.location?.address && !content.location.position) {
      fetchLocationPosition(content.location.address)
        .then((position) => {
          eventDetails.location = {
            ...eventDetails.location,
            position,
          }
          submitEvent()
        })
        .catch((err) => {
          handleError(setState, err, 'Error fetching location position.')
        })
    } else {
      submitEvent()
    }
  }

  // Toggle active filter
  const handleToggleFilter = (filterName) => {
    setState((prev) => ({
      ...prev,
      activeFilter: prev.activeFilter === filterName ? null : filterName,
    }))
  }

  const eventButtons = ['Past Events'].map((name) => (
    <button key={name} className='btn btn-outline-primary btn-sm rounded-pill mt-3 mb-0 ml-4' onClick={() => handleToggleFilter(name)}>
      {activeFilter === name && <span className='fas fa-times' />} {name}
    </button>
  ))

  // Handle search term change
  const handleSearchChange = (term) => {
    setState((prev) => ({...prev, searchTerm: term}))
  }

  // Filter events based on search and active filters
  const filteredEvents = getFilteredData(events, searchTerm)
    .filter((event) => {
      const eventDate = new Date(event.start_time)
      const {start, end} = getViewDateRange()
      return eventDate >= start && eventDate < end
    })
    .filter((event) => {
      if (activeFilter !== 'Past Events') {
        return new Date(event.end_time) >= new Date()
      }
      return true
    })

  // Handle input changes in the modal
  const handleInputChange = (e) => {
    const {name, value} = e.target
    console.log(`Input Change - Name: ${name}, Value: ${value}`)

    setState((prev) => {
      const updatedModal = {...prev.modalState}

      if (['start_time', 'end_time'].includes(name)) {
        updatedModal.content[name] = value
      } else if (name === 'startDate') {
        updatedModal.content.start_date = value || '' // Set as 'YYYY-MM-DD' string or empty string
      } else if (name === 'endDate') {
        updatedModal.content.end_date = value || '' // Set as 'YYYY-MM-DD' string or empty string
      } else if (name === 'location') {
        updatedModal.content.location =
          value.length === 0 ? {address: '', position: null} : {...updatedModal.content.location, address: value}
        if (value.length > 5) fetchSuggestions(value) // Ensure fetchSuggestions is defined
      } else {
        updatedModal.content[name] = value
      }

      return {...prev, modalState: {...updatedModal, suggestions: []}}
    })
  }

  // Handle invitee changes
  const handleInviteeChange = (inviteeId, action) => {
    setState((prev) => {
      const invitees = prev.modalState.content.invitees || []
      const index = invitees.findIndex((i) => i.user_id === inviteeId)
      const recipient = recipients.find((r) => r.id === inviteeId)

      if (action === 'assign' && recipient && index === -1) {
        return {
          ...prev,
          modalState: {
            ...prev.modalState,
            content: {
              ...prev.modalState.content,
              invitees: [...invitees, {user_id: recipient.id, user: recipient.name, deleted: false}],
            },
          },
        }
      }

      if (action === 'remove' && index !== -1) {
        const updatedInvitees = invitees.map((invitee, i) => (i === index ? {...invitee, deleted: true} : invitee))
        return {
          ...prev,
          modalState: {
            ...prev.modalState,
            content: {...prev.modalState.content, invitees: updatedInvitees},
          },
        }
      }

      return prev
    })
  }

  // Handle RRule changes
  const handleRRuleChange = (e) => {
    const {name, value} = e.target
    setState((prev) => {
      const rruleObj = {...prev.modalState.content.rruleObj, [name]: value}
      if (name === 'FREQ' && value !== 'WEEKLY') delete rruleObj.BYDAY

      const rrule = Object.entries(rruleObj)
        .filter(([k, v]) => k !== 'BYDAY' || (Array.isArray(v) && v.length > 0))
        .map(([k, v]) => `${k}=${Array.isArray(v) ? v.join(',') : v}`)
        .join(';')

      return {
        ...prev,
        modalState: {
          ...prev.modalState,
          content: {...prev.modalState.content, rrule, rruleObj},
        },
      }
    })
  }

  // Handle day of week changes in RRule
  const handleDayOfWeekChange = (e) => {
    const {value, checked} = e.target
    setState((prev) => {
      const currentDays = prev.modalState.content.rruleObj.BYDAY || []
      const updatedDays = checked ? [...currentDays, value] : currentDays.filter((day) => day !== value)

      const rruleObj =
        updatedDays.length > 0 ? {...prev.modalState.content.rruleObj, BYDAY: updatedDays} : {...prev.modalState.content.rruleObj}

      if (updatedDays.length === 0) delete rruleObj.BYDAY

      const rrule = Object.entries(rruleObj)
        .filter(([k, v]) => k !== 'BYDAY' || (Array.isArray(v) && v.length > 0))
        .map(([k, v]) => `${k}=${Array.isArray(v) ? v.join(',') : v}`)
        .join(';')

      return {
        ...prev,
        modalState: {
          ...prev.modalState,
          content: {...prev.modalState.content, rrule, rruleObj},
        },
      }
    })
  }

  // Toggle the interval between 1 and 2
  const toggleIntervalVisibility = () => {
    setState((prev) => {
      const currentInterval = parseInt(prev.modalState.content.rruleObj.INTERVAL, 10) || 1
      const newInterval = currentInterval > 1 ? '1' : '2'
      const rruleObj = {...prev.modalState.content.rruleObj, INTERVAL: newInterval}

      return {
        ...prev,
        modalState: {
          ...prev.modalState,
          content: {...prev.modalState.content, rruleObj},
        },
      }
    })
  }

  // Handle date click on the calendar to create a new event
  const handleDateClick = (info) => {
    setState((prev) => ({
      ...prev,
      modalState: {
        show: true,
        type: 'edit',
        isEditing: false,
        location: false,
        content: {
          id: null,
          name: '',
          details: '',
          start_time: '12:00',
          end_time: '13:00',
          start_date: formatDateForInput(info.date), // 'YYYY-MM-DD' string
          end_date: formatDateForInput(info.date), // 'YYYY-MM-DD' string
          rrule: '',
          location: {address: '', position: null},
          invitees: [],
        },
        markerPosition: [],
        suggestions: [],
        deletionMode: '',
        updateMode: '',
      },
    }))
  }

  // Handle event click on the calendar to view/edit the event
  const handleEventClick = (info) => {
    const event = events.find((e) => e.id === info.event.id)
    if (event) {
      handleRowSelect(event, selectedEvents, (updatedSelectedEvents) => {
        setState((prev) => ({...prev, selectedEvents: updatedSelectedEvents}))
      })

      const hasLocation = event.location?.address || event.location?.position?.length === 2
      setState((prev) => ({
        ...prev,
        modalState: {
          show: true,
          type: user.group === 'admin' ? 'edit' : 'view',
          isEditing: user.group === 'admin',
          location: hasLocation,
          content: {
            id: event.id,
            name: event.name,
            details: event.description,
            start_time:
              user.group === 'admin'
                ? formatTimeForInput(new Date(event.start_time))
                : convertUTCToLocalTime(formatDateWithTime(modalState.startDate, modalState.content.start_time)).toLocaleString([], {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  }),
            end_time:
              user.group === 'admin'
                ? formatTimeForInput(new Date(event.end_time))
                : convertUTCToLocalTime(formatDateWithTime(modalState.endDate, modalState.content.end_time)).toLocaleString([], {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  }),
            start_date: formatDateForInput(new Date(event.start_time)),
            end_date: formatDateForInput(new Date(event.end_time)),
            rrule: event.rrule || '',
            location: event.location || {position: [], address: ''},
            rruleObj: event.rrule ? parseRRule(event.rrule) : {},
            invitees: event.invitees || [],
          },
          markerPosition: event.location?.position || [],
          suggestions: [],
          deletionMode: '',
          updateMode: '',
        },
      }))

      originalEventRef.current = {
        ...event,
        // Deep copy invitees to prevent mutation
        invitees: event.invitees ? JSON.parse(JSON.stringify(event.invitees)) : [],
      }
    }
  }

  // Handle share button click
  const handleShareClick = () => {
    setState((prev) => ({
      ...prev,
      modalState: {...prev.modalState, show: true, type: 'share'},
    }))
  }

  // Handle delete button click
  const handleDeleteClick = () => {
    setState((prev) => ({
      ...prev,
      modalState: {...prev.modalState, show: true, type: 'delete', deletionMode: ''},
    }))
  }

  // Handle update button click
  const handleUpdateClick = () => {
    setState((prev) => ({
      ...prev,
      modalState: {...prev.modalState, show: true, type: 'update', updateMode: ''},
    }))
  }

  // Handle closing the modal
  const handleCloseModal = () => {
    setState((prev) => ({
      ...prev,
      selectedRecipients: [],
      modalState: {
        show: false,
        type: '',
        isEditing: false,
        location: false,
        markerPosition: [],
        suggestions: [],
        content: {
          id: null,
          name: '',
          details: '',
          start_time: '12:00',
          end_time: '13:00',
          rrule: '',
          location: {position: null, address: ''},
          invitees: [],
        },
        startDate: new Date(),
        endDate: new Date(),
        deletionMode: '',
        updateMode: '',
      },
    }))
  }

  // Fetch location suggestions with debounce
  const fetchSuggestions = useCallback(
    debounce((value) => {
      fetch(`https://nominatim.openstreetmap.org/search?q=${value}&format=json&limit=5`)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            const parsedData = data.map((item) => ({
              ...item,
              display_name: parseAddressSugg(item.display_name),
            }))
            setState((prev) => ({
              ...prev,
              modalState: {...prev.modalState, suggestions: parsedData},
            }))
          }
        })
        .catch((error) => handleError(setState, error, 'Error fetching location suggestions.'))
    }, 300),
    []
  )

  // Handle selecting a location suggestion
  const handleSuggestionSelect = (suggestion) => {
    const {lat, lon, display_name: location} = suggestion
    const position = [parseFloat(lat), parseFloat(lon)]

    if (isNaN(position[0]) || isNaN(position[1])) {
      console.error('Invalid coordinates received from suggestion')
      return
    }

    setState((prev) => ({
      ...prev,
      modalState: {
        ...prev.modalState,
        content: {...prev.modalState.content, location: {position, address: location}},
        markerPosition: position,
        suggestions: [],
      },
    }))
  }

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='section-header'>
          <h1>Schedule</h1>
        </div>
        <div className='section-body'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <FullCalendar
                    ref={calendarRef}
                    timeZone='local'
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView='dayGridMonth'
                    events={filteredEvents.map((event) => ({
                      id: event.id,
                      title: event.name,
                      start: new Date(event.start_time),
                      end: new Date(event.end_time),
                      description: event.description,
                    }))}
                    height='680px'
                    dayCellClassNames={(arg) =>
                      ['fc-day', hoveredDate === arg.date.toISOString().split('T')[0] && 'fc-day-hover'].filter(Boolean)
                    }
                    dateClick={handleDateClick}
                    eventClick={handleEventClick}
                    dayCellDidMount={(info) => {
                      info.el.addEventListener('mouseenter', () =>
                        setState((prev) => ({...prev, hoveredDate: info.date.toISOString().split('T')[0]}))
                      )
                      info.el.addEventListener('mouseleave', () => setState((prev) => ({...prev, hoveredDate: null})))
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Events List */}
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header d-flex align-items-center row w-100'>
                  <div className='col'>
                    <h4>Events</h4>
                  </div>
                  <div className='col-auto'>
                    <div className='card-header-form'>
                      <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-between align-items-center m-0 p-0'>
                  <div style={{overflowX: 'auto'}}>{eventButtons}</div>
                  <div className='input-group-btn d-flex justify-content-end align-items-center mt-3 mb-0' style={{marginRight: '38px'}}>
                    {selectedEvents.length > 0 && <h6 className='mt-2 mr-3 mb-0'>{selectedEvents.length} selected</h6>}
                    {selectedEvents.length > 0 && user.group === 'admin' && (
                      <i className='fas fa-times text-danger mt-1 mr-3' style={{fontSize: '1.5rem'}} onClick={handleDeleteClick}></i>
                    )}
                    {user.group === 'admin' && (
                      <i
                        className='fas fa-plus text-primary mt-1 mr-3'
                        style={{fontSize: '1.3rem'}}
                        onClick={() => handleDateClick({date: new Date()})}
                      ></i>
                    )}
                    <i className='fas fa-external-link-alt text-primary mt-0' style={{fontSize: '1.2rem'}} onClick={handleShareClick}></i>
                  </div>
                </div>
                <div
                  className='card-body'
                  style={{...cardBodyStyle, maxHeight: '800px'}}
                  ref={(el) => {
                    if (el) el.scrollLeft = 10
                  }}
                >
                  <table className='table table-hover'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className='text-nowrap'>Name</th>
                        <th className='text-nowrap'>Starts</th>
                        <th className='text-nowrap'>Ends</th>
                        <th>Location</th>
                        {user.group === 'admin' && <th className='text-nowrap'>Check - Ins</th>}
                        {user.group === 'admin' && <th>User</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredEvents.map((event, index) => (
                        <tr
                          key={event.id}
                          onClick={() =>
                            handleRowSelect(event, selectedEvents, (updatedSelectedEvents) => {
                              setState((prev) => ({...prev, selectedEvents: updatedSelectedEvents}))
                            })
                          }
                          onMouseDown={() => setState((prev) => ({...prev, isDragging: true}))}
                          onMouseUp={() => setState((prev) => ({...prev, isDragging: false}))}
                          onMouseEnter={() =>
                            handleMouseEnter(event, isDragging, selectedEvents, (updatedSelectedEvents) => {
                              setState((prev) => ({...prev, selectedEvents: updatedSelectedEvents}))
                            })
                          }
                          className={selectedEvents.some((e) => e.id === event.id) ? 'table-secondary' : ''}
                        >
                          <th scope='row'>{index + 1}</th>
                          <td className='text-nowrap'>{event.name}</td>
                          <td className='text-nowrap'>
                            {new Date(event.start_time).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})}
                          </td>
                          <td className='text-nowrap'>
                            {new Date(event.end_time).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})}
                          </td>
                          <td>{event.location ? splitAddress(event.location.address) : ''}</td>
                          {user.group === 'admin' && event.invitees && (
                            <td>
                              {event.invitees.map((invitee, i) => (
                                <div key={i} className='d-flex flex-column text-nowrap mb-1'>
                                  <span>{invitee.user || ''}</span>
                                  {invitee.checkin_time && (
                                    <span>
                                      In: {new Date(invitee.checkin_time).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})}
                                    </span>
                                  )}
                                  {invitee.checkout_time && (
                                    <span>
                                      Out: {new Date(invitee.checkout_time).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})}
                                    </span>
                                  )}
                                </div>
                              ))}
                            </td>
                          )}
                          {user.group === 'admin' && <td>{event.user || 'N/A'}</td>}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}
      <CalendarModal
        /* State and Data Props */
        user={user}
        modalState={modalState}
        setModalState={(updatedState) => setState((prev) => ({...prev, ...updatedState}))}
        selectedEvents={selectedEvents}
        recipients={recipients}
        selectedRecipients={selectedRecipients}
        setSelectedRecipients={(selected) => setState((prev) => ({...prev, selectedRecipients: selected}))}
        shareSearch={shareSearch}
        setShareSearch={(search) => setState((prev) => ({...prev, shareSearch: search}))}
        toggleIntervalVisibility={toggleIntervalVisibility}
        /* Handler Functions */
        handleCloseModal={handleCloseModal}
        handleDeleteClick={handleDeleteClick}
        handleDeleteSelected={() => handleDeleteSelected(modalState.deletionMode)}
        handleInputChange={handleInputChange}
        handleRRuleChange={handleRRuleChange}
        handleShare={handleShare}
        handleSubmit={handleSubmit}
        handleSuggestionSelect={handleSuggestionSelect}
        handleUpdateSelected={() => handleUpdateSelected(modalState.updateMode)}
        handleDayOfWeekChange={handleDayOfWeekChange}
        handleInviteeChange={handleInviteeChange}
      />

      {/* Styles */}
      <style jsx>{`
        .fc-day-hover {
          background-color: #e6e6e6;
          cursor: pointer;
        }
      `}</style>
    </div>
  )
}

export default Calendar
