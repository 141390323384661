import React from "react"

export default function Footer() {
  return (
    <footer className="main-footer">
      <div className="footer-left">
        <i className="fas fa-copyright"></i> 2024
        <div className="bullet" />
        BabyD Agency
      </div>
      <div className="footer-right"> 0.2 </div>
    </footer>
  )
}
