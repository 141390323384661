// index.js

import React from 'react'
import {Form} from 'react-bootstrap'
import {frequencyMap, dayMap, stateAbbreviations} from './const'

// styles
export const cardStyle = {
  height: '520px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
}

export const cardBodyStyle = {
  overflowY: 'auto',
  overflowX: 'auto',
}

// validation functions
export const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
  return re.test(String(email).toLowerCase())
}

export const validatePhone = (phone) => {
  const re = /^[0-9]{10}$/
  return re.test(String(phone))
}

export const validateAddress = (address) => {
  const {street, city, state, postalCode} = address
  if (!/^.{6,}$/.test(street)) return 'invalid street address'
  if (!/^.{3,}$/.test(city)) return 'invalid city'
  if (!/^[A-Z]{2}$/.test(state)) return 'invalid state'
  if (!/^[0-9]{5}(-[0-9]{4})?$/.test(postalCode)) return 'invalid postal code'
  return null
}

// formatting functions
export const formatDateWithTime = (dateString, timeString) => {
  return new Date(`${dateString}T${timeString}:00`)
}

export const formatDateForInput = (date) => {
  if (!date) return ''
  const d = new Date(date)
  const year = d.getFullYear()
  const month = String(d.getMonth() + 1).padStart(2, '0')
  const day = String(d.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const formatTimeForInput = (date) => {
  if (!date) return ''
  const d = new Date(date)
  const hours = String(d.getHours()).padStart(2, '0')
  const minutes = String(d.getMinutes()).padStart(2, '0')
  return `${hours}:${minutes}`
}

export const formatPhone = (phoneString) => {
  if (!phoneString || phoneString.length !== 10) return phoneString
  return `(${phoneString.slice(0, 3)}) ${phoneString.slice(3, 6)} ${phoneString.slice(6)}`
}

export const formatAddress = (jsonString) => {
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

  if (typeof jsonString !== 'string') return jsonString
  const trimmed = jsonString.trim()
  if (!/^(\{.*\}|\[.*\])$/.test(trimmed)) return jsonString

  try {
    const address = JSON.parse(trimmed)
    const {street, city, state, postalCode} = address
    if (street && city && state && postalCode) {
      return `${capitalize(street)}, ${capitalize(city)}, ${state.toUpperCase()} ${postalCode}`
    }
    return ''
  } catch {
    return jsonString
  }
}

export const parseAddress = (addressString) => {
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)
  const pattern = /^(.*?), (.*?), ([A-Za-z]{2}) (\d{5})$/
  const match = pattern.exec(addressString.trim())

  if (match) {
    const [, street, city, state, postalCode] = match
    return JSON.stringify({
      street: capitalize(street.trim()),
      city: capitalize(city.trim()),
      state: state.toUpperCase(),
      postalCode: postalCode.trim(),
    })
  }
  return null
}

export const parseAddressSugg = (address) => {
  const parts = address.split(',').map((part) => part.trim())
  if (parts.length < 5) {
    console.error('unexpected address format:', address)
    return address
  }

  const [streetNumber, streetName, neighborhood, ...rest] = parts
  const zipCodeMatch = rest.find((part) => /\d{5}(-\d{4})?$/.test(part))
  const zipCode = zipCodeMatch || ''
  const stateName = rest[rest.indexOf(zipCode) - 1] || ''
  const stateAbbr = stateAbbreviations[stateName] || stateName

  return `${streetNumber} ${streetName}, ${neighborhood}, ${stateAbbr} ${zipCode}`
}

export const truncateText = (text, maxLength = 14) => {
  if (typeof text !== 'string') return ''

  const suffix = '...'
  return text.length > maxLength ? `${text.substring(0, maxLength - suffix.length)}${suffix}` : text
}

export const splitAddress = (address) => {
  const parts = address.split(',')
  if (parts.length < 3) {
    return <span className='text-nowrap'>{address.trim()}</span>
  }
  const [first, ...rest] = parts
  const second = rest.join(',').trim()
  return (
    <>
      <span className='text-nowrap'>{`${first.trim()},`}</span>
      <br />
      <span className='text-nowrap'>{second}</span>
    </>
  )
}

export const daysOfWeek = (days) => {
  return days.map((day) => dayMap[day] || day).join(', ')
}

export const formatTimeTo12Hour = (time) => {
  let [hours, minutes] = time.split(':').map(Number)
  const suffix = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12
  return `${hours}:${minutes.toString().padStart(2, '0')} ${suffix}`
}

export const formatActivities = (data) => {
  let formatted = []

  if (data.event && data.event.eventId) {
    formatted.push(`event: ${data.event.eventId}`)
  }

  if (Array.isArray(data.feed) && data.feed.length > 0) {
    const feedData = data.feed
      .map((feedEntry) => `${formatTimeTo12Hour(feedEntry.time)} ${feedEntry.nurse} ${feedEntry.feedOz} ${feedEntry.pumpOz}`)
      .join(', ')
    formatted.push(`feed: ${feedData}`)
  }

  if (Array.isArray(data.sleep) && data.sleep.length > 0) {
    const sleepData = data.sleep
      .map((sleepEntry) => `${formatTimeTo12Hour(sleepEntry.start)} - ${formatTimeTo12Hour(sleepEntry.end)}`)
      .join(', ')
    formatted.push(`sleep: ${sleepData}`)
  }

  if (Array.isArray(data.diapers) && data.diapers.length > 0) {
    const diapersData = data.diapers
      .map((diaperEntry) => `${formatTimeTo12Hour(diaperEntry.time)} ${diaperEntry.wet} ${diaperEntry.dirty}`)
      .join(', ')
    formatted.push(`diapers: ${diapersData}`)
  }

  // Join all formatted data into a single string separated by newlines
  const result = formatted.join('\n')
  return result
}

export const parseActivities = (dataStr) => {
  // Internal helper function to parse the event line
  const parseEvent = (eventStr) => {
    const eventRegex = /^event:\s*([a-f0-9\-]+)$/i
    const match = eventStr.match(eventRegex)
    if (match) {
      return {eventId: match[1]}
    } else {
      console.warn(`Event entry didn't match expected format: "${eventStr}"`)
      return null
    }
  }

  // Internal helper function to parse the feed entries
  const parseFeed = (feedStr) => {
    const feeds = feedStr.split(',').map((f) => f.trim())
    const result = []

    // Updated regex to capture time, optional nurse, optional duration, feedOz, pumpOz
    // Duration is now a number without 'min'
    const feedRegex = /^(\d{1,2}:\d{2}\s?[AP]M)(?:\s+([RL])(?:\s+(\d+))?)?\s+(\d+)\s+(\d+)$/

    feeds.forEach((feed) => {
      const match = feed.match(feedRegex)
      if (match) {
        const [, time, nurse, duration, feedOz, pumpOz] = match
        const feedEntry = {
          time: time.replace(/\s+/g, ''), // Remove any extra spaces in time
          feedOz: parseInt(feedOz, 10),
          pumpOz: parseInt(pumpOz, 10),
        }
        if (nurse) {
          feedEntry.nurse = nurse
        }
        if (duration) {
          feedEntry.duration = parseInt(duration, 10)
        }
        result.push(feedEntry)
      } else {
        console.warn(`Feed entry didn't match expected format: "${feed}"`)
      }
    })

    return result
  }

  // Internal helper function to parse the sleep entries
  const parseSleep = (sleepStr) => {
    const sleeps = sleepStr.split(',').map((s) => s.trim())
    const result = []

    // Regex to match sleep periods
    const sleepRegex = /^(\d{1,2}:\d{2}\s?[AP]M)\s*-\s*(\d{1,2}:\d{2}\s?[AP]M)$/

    sleeps.forEach((sleep) => {
      const match = sleep.match(sleepRegex)
      if (match) {
        const [, start, end] = match
        result.push({
          start: start.replace(/\s+/g, ''),
          end: end.replace(/\s+/g, ''),
        })
      } else {
        console.warn(`Sleep entry didn't match expected format: "${sleep}"`)
      }
    })

    return result
  }

  // Internal helper function to parse the diaper entries
  const parseDiapers = (diaperStr) => {
    const diapers = diaperStr.split(',').map((d) => d.trim())
    const result = []

    // Regex to match diaper entries with optional wet and dirty fields
    const diaperRegex = /^(\d{1,2}:\d{2}\s?[AP]M)(?:\s+(true|false))?(?:\s+(true|false))?$/i

    diapers.forEach((diaper) => {
      const match = diaper.match(diaperRegex)
      if (match) {
        const [, time, wet, dirty] = match
        const diaperEntry = {
          time: time.replace(/\s+/g, ''),
        }
        if (wet !== undefined) {
          diaperEntry.wet = wet.toLowerCase() === 'true'
        }
        if (dirty !== undefined) {
          diaperEntry.dirty = dirty.toLowerCase() === 'true'
        }
        result.push(diaperEntry)
      } else {
        console.warn(`Diaper entry didn't match expected format: "${diaper}"`)
      }
    })

    return result
  }

  // Initialize the result object
  const result = {event: null, feed: [], sleep: [], diapers: []}

  // Split the input data into lines and process each line
  const lines = dataStr.trim().split('\n')

  lines.forEach((line) => {
    const colonIndex = line.indexOf(':')
    if (colonIndex === -1) {
      console.warn(`No colon found in line: "${line}"`)
      return
    }

    const key = line
      .substring(0, colonIndex)
      .trim()
      .toLowerCase()
    const value = line.substring(colonIndex + 1).trim()

    switch (key) {
      case 'event':
        const event = parseEvent(line)
        if (event) {
          result.event = event
        }
        break
      case 'feed':
        result.feed = parseFeed(value)
        break
      case 'sleep':
        result.sleep = parseSleep(value)
        break
      case 'diapers':
        result.diapers = parseDiapers(value)
        break
      default:
        console.warn(`Unknown key encountered: "${key}"`)
    }
  })

  return result
}

// conversion functions
export const convertUTCToLocalTime = (dateString) => {
  const utcDate = new Date(dateString)
  return new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000)
}

export const isInRadius = (lat1, lon1, lat2, lon2, radius) => {
  const toRadians = (deg) => deg * (Math.PI / 180)
  const R = 6371 // earth radius in km
  const dLat = toRadians(lat2 - lat1)
  const dLon = toRadians(lon2 - lon1)
  const a = Math.sin(dLat / 2) ** 2 + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) ** 2
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c
  return distance <= radius
}

// selection handlers
export const handleRowSelect = (item, selectedItems, setSelectedItems) => {
  if (selectedItems.includes(item)) {
    setSelectedItems(selectedItems.filter((i) => i !== item))
  } else {
    setSelectedItems([...selectedItems, item])
  }
}

export const handleMouseDown = (setIsDragging) => {
  setIsDragging(true)
}

export const handleMouseUp = (setIsDragging) => {
  setIsDragging(false)
}

export const handleMouseEnter = (item, isDragging, selectedItems, setSelectedItems) => {
  if (isDragging) {
    handleRowSelect(item, selectedItems, setSelectedItems)
  }
}

export const handleDeleteSelectedItems = (selectedItems, deleteFunc) => {
  if (!selectedItems.length) {
    console.error('no items selected for deletion')
    return
  }
  const itemIds = selectedItems.map((item) => item.id)
  deleteFunc(itemIds).catch((error) => {
    console.error('error deleting items:', error)
  })
}

// exports
export {frequencyMap, dayMap, stateAbbreviations} from './const'

// stacks
export {UserCard, DocumentPreview, CheckInCard, ActivitiesEditCard, ActivitiesInfoCard, ClientReminders} from './stacks'

// modals
export {CalendarModal, DocumentModal} from './modals'

// search
export {getFilteredData, SearchBar} from './search'

// rrule
export {parseRRule, formatDate, generateOccurrencesWithExdates, addExdatesForFutureOccurrences, isFirstOccurrence} from './recurrence'

// error
export {handleError} from './error'
