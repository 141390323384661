import React, {createContext, useContext, useState, useEffect, useMemo} from 'react'
import config from '../config'
import {getUserById} from './service'
import {handleError} from '../pages/utils'
import {jwtDecode} from 'jwt-decode'

const AuthContext = createContext({
  user: null,
  setUser: () => {},
  login: () => Promise.resolve(false),
  logout: () => {},
  isAuthenticated: false,
  loading: true,
  error: null,
})

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({children}) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user')
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser)
      // Remove 'image' and 'settings' fields if they exist
      const {image, settings, ...limitedUserData} = parsedUser
      return limitedUserData
    }
    return null
  })
  const [isAuthenticated, setIsAuthenticated] = useState(!!user)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null) // Added error state

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      try {
        const decodedToken = jwtDecode(token)
        if (decodedToken.exp * 1000 > Date.now()) {
          setIsAuthenticated(true)
          fetchAndUpdateUser(decodedToken.userId)
        } else {
          localStorage.removeItem('token')
          setLoading(false)
        }
      } catch (error) {
        handleError(setError, error, 'Token decoding error')
        localStorage.removeItem('token')
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }, [])

  const fetchAndUpdateUser = async (userId) => {
    return getUserById(userId)
      .then((userData) => {
        // Remove 'image' and 'settings' fields
        const {image, settings, ...limitedUserData} = userData

        setUser(limitedUserData)
        localStorage.setItem('user', JSON.stringify(limitedUserData))
        console.log('User updated:', limitedUserData.email)
      })
      .catch((error) => {
        handleError(setError, error, 'Fetch user data error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const login = async (email, password) => {
    return fetch(`${config.REACT_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email, password}),
    })
      .then((response) => response.json().then((data) => ({status: response.status, data})))
      .then(({status, data}) => {
        if (status >= 200 && status < 300 && data?.token) {
          const decodedToken = jwtDecode(data.token)
          setIsAuthenticated(true)

          // Store the token and login time in localStorage
          localStorage.setItem('token', data.token)
          localStorage.setItem('logTime', new Date().toISOString())

          return fetchAndUpdateUser(decodedToken.userId)
            .then(() => ({isAuthenticated: true, message: ''}))
            .catch((error) => {
              handleError(setError, error, 'Fetch user error')
              return {isAuthenticated: false, message: 'Fetch user error'}
            })
        } else {
          return {isAuthenticated: false, message: data.message}
        }
      })
      .catch((error) => {
        handleError(setError, error, 'Login error')
        return {isAuthenticated: false, message: 'Login error'}
      })
  }

  const logout = () => {
    try {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('logTime')
      setUser(null)
      setIsAuthenticated(false)
    } catch (error) {
      handleError(setError, error, 'Logout error')
    }
  }

  const contextValue = useMemo(
    () => ({
      user,
      setUser,
      login,
      logout,
      isAuthenticated,
      loading,
      error, // Included error in context
    }),
    [user, isAuthenticated, loading, error]
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}
