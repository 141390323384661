import React, {useEffect, useState} from 'react'
import {fetchOrders, deleteOrders} from '../../contexts/service'
import {cardBodyStyle, handleRowSelect, handleMouseDown, handleMouseUp, handleMouseEnter} from '../utils'

const ShopData = () => {
  const [orders, setOrders] = useState([])

  const [selectedOrders, setSelectedOrders] = useState([])
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    fetchOrders(setOrders)
  }, [])

  const handleDeleteSelected = async () => {
    const itemIds = selectedOrders.map((item) => item.id)
    const confirmDelete = window.confirm('Are you sure you want to delete the selected orders?')
    if (confirmDelete) {
      deleteOrders(itemIds).then(() => {
        setSelectedOrders([])
        fetchOrders(setOrders)
      })
    }
  }

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-header'>
                <h4>Orders</h4>
              </div>
              <div className='input-group-btn d-flex justify-content-end align-items-center mt-2 mb-0'>
                {selectedOrders.length > 0 && <h6 className='mt-1 mr-3 mb-0'>{selectedOrders.length} selected</h6>}
                {selectedOrders.length > 0 && (
                  <i className='fas fa-times text-danger mt-0 mr-3' style={{fontSize: '1.5rem'}} onClick={handleDeleteSelected}></i>
                )}
              </div>
              <div className='card-body' style={cardBodyStyle}>
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th scope='col'>ID</th>
                      <th scope='col'>Status</th>
                      <th scope='col'>Currency</th>
                      <th scope='col'>Prices Include Tax</th>
                      <th scope='col'>Date Created</th>
                      <th scope='col'>Date Modified</th>
                      <th scope='col'>Shipping Total</th>
                      <th scope='col'>Total</th>
                      <th scope='col'>Order Key</th>
                      <th scope='col'>Billing First Name</th>
                      <th scope='col'>Billing Last Name</th>
                      <th scope='col'>Billing Company</th>
                      <th scope='col'>Billing Address 1</th>
                      <th scope='col'>Billing Address 2</th>
                      <th scope='col'>Billing City</th>
                      <th scope='col'>Billing State</th>
                      <th scope='col'>Billing Postcode</th>
                      <th scope='col'>Billing Country</th>
                      <th scope='col'>Billing Email</th>
                      <th scope='col'>Billing Phone</th>
                      <th scope='col'>Shipping First Name</th>
                      <th scope='col'>Shipping Last Name</th>
                      <th scope='col'>Shipping Company</th>
                      <th scope='col'>Shipping Address 1</th>
                      <th scope='col'>Shipping Address 2</th>
                      <th scope='col'>Shipping City</th>
                      <th scope='col'>Shipping State</th>
                      <th scope='col'>Shipping Postcode</th>
                      <th scope='col'>Shipping Country</th>
                      <th scope='col'>Shipping Phone</th>
                      <th scope='col'>Line Item ID</th>
                      <th scope='col'>Line Item Name</th>
                      <th scope='col'>Line Item Product ID</th>
                      <th scope='col'>Line Item Quantity</th>
                      <th scope='col'>Line Item Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order) => (
                      <tr
                        key={order.id}
                        onClick={() => handleRowSelect(order, selectedOrders, setSelectedOrders)}
                        onMouseDown={() => handleMouseDown(setIsDragging)}
                        onMouseUp={() => handleMouseUp(setIsDragging)}
                        onMouseEnter={() => handleMouseEnter(order, isDragging, selectedOrders, setSelectedOrders)}
                        className={selectedOrders.includes(order) ? 'table-secondary' : ''}
                      >
                        <td>{order.id}</td>
                        <td>{order.status}</td>
                        <td>{order.currency}</td>
                        <td>{order.prices_include_tax}</td>
                        <td>{order.date_created}</td>
                        <td>{order.date_modified}</td>
                        <td>{order.shipping_total}</td>
                        <td>{order.total}</td>
                        <td>{order.order_key}</td>
                        <td>{order.billing.first_name}</td>
                        <td>{order.billing.last_name}</td>
                        <td>{order.billing.company}</td>
                        <td>{order.billing.address_1}</td>
                        <td>{order.billing.address_2}</td>
                        <td>{order.billing.city}</td>
                        <td>{order.billing.state}</td>
                        <td>{order.billing.postcode}</td>
                        <td>{order.billing.country}</td>
                        <td>{order.billing.email}</td>
                        <td>{order.billing.phone}</td>
                        <td>{order.shipping.first_name}</td>
                        <td>{order.shipping.last_name}</td>
                        <td>{order.shipping.company}</td>
                        <td>{order.shipping.address_1}</td>
                        <td>{order.shipping.address_2}</td>
                        <td>{order.shipping.city}</td>
                        <td>{order.shipping.state}</td>
                        <td>{order.shipping.postcode}</td>
                        <td>{order.shipping.country}</td>
                        <td>{order.shipping.phone}</td>
                        {order.line_items.map((item) => (
                          <React.Fragment key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.product_id}</td>
                            <td>{item.quantity}</td>
                            <td>{item.subtotal}</td>
                          </React.Fragment>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ShopData
