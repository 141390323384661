// src/config.js
const config = {
  REACT_URL: "https://backendcare.onrender.com",
  // REACT_URL: 'http://localhost:4000',
  GOPHER_URL: 'https://babyddagency.com',
  SIGNUP_USER: 'ck_c0ee466a6524ca6ff8ab620c71b02769de0fdf73',
  SIGNUP_PASS: 'cs_3fe626e46ca3559e36bc7ed1244c3d37ba0f4161',
  ORDER_USER: 'ck_80c48b747310de16e6b3094b802897ced079e0be',
  ORDER_PASS: 'cs_e5f49beb780453e6d81cf30b0926c42585cf58fa',
}

export default config
