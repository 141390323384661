import React, { useEffect, useState } from "react";
import ToggleDropdown from "./ToggleDropdown"; // Assuming ToggleDropdown replaces ToggleData
import Search from "./Search";
import UserDropdown from "./UserDropdown";

const Header = () => {
  const [notifications, setNotifications] = useState([]);

  const searchResultData = [
    // Define your search result data here
  ];

  const NotifyData = {
    toggleName: "notification-toggle",
    iconName: "far fa-bell",
    headerLeft: "Notifications",
    listClass: "dropdown-list-icons",
    content: notifications.map(notification => ({
      iClassname: notification.iClassname,
      bgClass: notification.bgClass,
      description: notification.description,
      textCls: notification.textCls,
      time: notification.time,
      unreadClass: notification.unreadClass,
    }))
  };

  return (
    <div>
      <div className="navbar-bg" />
      <nav className="navbar navbar-expand-lg main-navbar">
        <form className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li>
              <a
                href="#"
                data-toggle="sidebar"
                className="nav-link nav-link-lg"
              >
                <i className="fas fa-bars" />
              </a>
            </li>
{/* 
            <li>
              <a
                href="#"
                data-toggle="search"
                className="nav-link nav-link-lg d-sm-none"
              >
                <i className="fas fa-search" />
              </a>
            </li> */}
          </ul>
          {/* <Search searchResultData={searchResultData} /> */}
        </form>
        <ul className="navbar-nav ">
          {/* <ToggleDropdown data={NotifyData} /> */}
          <UserDropdown />
        </ul>
      </nav>
    </div>
  );
};

export default Header;