import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import {useAuth} from '../../contexts/authContext'
import {getUsers, fetchSignups, fetchOrders} from '../../contexts/service'

const Dashboard = () => {
  const {user} = useAuth()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState('Month')
  const [signups, setSignups] = useState(0)
  const [orders, setOrders] = useState(0)
  const [currentClients, setcurrentClients] = useState(0)

  useEffect(() => {
    loadUsers()
    fetchSignups(setSignups)
    fetchOrders((fetchedOrders) => {
      setOrders(fetchedOrders.length)
    })
  }, [])

  const loadUsers = async () => {
    const data = await getUsers(user.id).catch(() => [])
    const clients = data.filter((item) => item.group === 'client')
    setcurrentClients(clients.length)
  }

  const months = ['May']

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const handleMonthSelect = (month) => {
    setSelectedMonth(month)
    setDropdownOpen(false)
  }

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='row'>
          <div className='col-lg-4 col-md-4 col-sm-12'>
            <Link to='/shop-data' className='card card-statistic-2' style={{cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>
              {/* <div className='card-stats' style={{marginBottom: "-40px"}}>
                <div className='card-stats-title'>
                  Order Statistics -
                  <div className='dropdown d-inline ml-4'>
                    <a
                      className='font-weight-600 dropdown-toggle'
                      onClick={toggleDropdown}
                      href='#'
                      id='orders-month'
                      aria-expanded={dropdownOpen ? "true" : "false"}
                    >
                      {selectedMonth}
                    </a>
                    <ul className={`dropdown-menu dropdown-menu-sm ${dropdownOpen ? "show" : ""}`}>
                      <li className='dropdown-title'>Select Month</li>
                      {months.map((month, index) => (
                        <li key={index}>
                          <a href='#' className='dropdown-item' onClick={() => handleMonthSelect(month)}>
                            {month}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div> */}
              <div className='card-icon shadow-primary bg-primary'>
                <i className='fas fa-archive'></i>
              </div>
              <div className='card-wrap'>
                <div className='card-header'>
                  <h4>All Orders</h4>
                </div>
                <div className='card-body'>{orders}</div>
              </div>
            </Link>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12'>
            <Link to='/users/clients' className='card card-statistic-2' style={{cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>
              {/* <div className='card-stats'>
                <div className='card-chart'></div>
              </div> */}
              <div className='card-icon shadow-primary bg-primary'>
                <i className='fas fa-users'></i>
              </div>
              <div className='card-wrap'>
                <div className='card-header'>
                  <h4>Active Clients</h4>
                </div>
                <div className='card-body'>{currentClients}</div>
              </div>
            </Link>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12'>
            <Link to='/signup-data' className='card card-statistic-2' style={{cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>
              {/* <div className='card-stats'>
                <div className='card-chart'></div>
              </div> */}
              <div className='card-icon shadow-primary bg-primary'>
                <i className='fas fa-user-graduate'></i>
              </div>
              <div className='card-wrap'>
                <div className='card-header'>
                  <h4>All Students</h4>
                </div>
                <div className='card-body'>{signups}</div>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <div>
        <canvas height='400'></canvas>
      </div>
    </div>
  )
}

export default Dashboard
