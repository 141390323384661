import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import logo from '../../assets/logo/logo.png'
import {verifyEmail} from '../../contexts/service'

export const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const history = useHistory()

  const handleSubmit = (event) => {
    event.preventDefault()
    setMessage('')
    setError('')

    verifyEmail(email, 'resetPassword')
      .then(() => {
        setMessage('A link to reset your password has been sent to your email.')
      })
      .catch(() => {
        setError('Failed to send reset link. Please try again.')
      })
  }

  return (
    <div id='app'>
      <section className='section'>
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4'>
              <div className='login-brand'>
                <img src={logo} alt='logo' className='w-50' style={{margin: '-15%'}} />
              </div>

              <div className='card card-primary'>
                <div className='card-header'>
                  <h4>Forgot Password</h4>
                </div>

                <div className='card-body'>
                  <p className='text-muted'>We will send a link to reset your password</p>
                  {message && <div className='alert alert-success'>{message}</div>}
                  {error && <div className='alert alert-danger'>{error}</div>}
                  <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                      <label htmlFor='email'>Email</label>
                      <input
                        id='email'
                        type='email'
                        className='form-control'
                        name='email'
                        tabIndex='1'
                        required
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className='form-group'>
                      <button type='submit' className='btn btn-primary btn-lg btn-block' tabIndex='4'>
                        Forgot Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <canvas height='200'></canvas>
      </div>
    </div>
  )
}

export default ForgotPassword
