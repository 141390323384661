import React, {useEffect, useState, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import {Modal, Button, Form} from 'react-bootstrap'
import {useAuth} from '../contexts/authContext'
import {getUsers, updateUser, getRecipientsByUserId, addRelated, deleteRelated} from '../contexts/service'
import {getFilteredData, SearchBar} from './utils/search'
import {
  cardBodyStyle,
  validatePhone,
  validateAddress,
  formatPhone,
  formatAddress,
  parseAddress,
  handleRowSelect,
  handleMouseDown,
  handleMouseUp,
  handleMouseEnter,
  ActivitiesInfoCard,
  ClientReminders,
} from './utils'

const Users = () => {
  const {user} = useAuth()

  // Data-related states
  const [userData, setUserData] = useState([])
  const [originalData, setOriginalData] = useState([])
  const [editedUsers, setEditedUsers] = useState(new Set())
  const [undoStack, setUndoStack] = useState([])

  // UI-related states
  const [editing, setEditing] = useState({})
  const [isDragging, setIsDragging] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedUserData, setSelectedUserData] = useState(null)

  // Dropdown-related states
  const dropdownRef = useRef(null)
  const [dropdownState, setDropdownState] = useState({
    selectedGroup: 'All',
    dropdownOpen: false,
  })

  // Modal-related states
  const [groupModalState, setGroupModalState] = useState({
    showGroupModal: false,
    newGroup: '',
  })
  const [recipients, setRecipients] = useState([])
  const [addRecipients, setAddRecipients] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [error, setError] = useState(null)
  const location = useLocation()

  const loadUsers = async () => {
    const data = await getUsers(user.id).catch(() => [])
    setUserData(data)
    setOriginalData(JSON.parse(JSON.stringify(data)))
  }

  const loadRecipients = async () => {
    const recipients = await getRecipientsByUserId(user.id).catch((error) => {
      console.error('Error loading recipients:', error)
      return []
    })

    if (recipients.length > 0) {
      console.log('Recipients loaded:', recipients)
      setRecipients(recipients)
    }
  }

  useEffect(() => {
    loadUsers()
    loadRecipients()
  }, [])

  useEffect(() => {
    if (location.pathname === '/users/clients') {
      setDropdownState((prevState) => ({
        ...prevState,
        selectedGroup: 'Clients',
      }))
    }
  }, [location.pathname])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownState((prevState) => ({
          ...prevState,
          dropdownOpen: false,
        }))
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleUndoClick = () => {
    const lastChange = undoStack.pop()
    if (lastChange) {
      const {index, field, previousValue} = lastChange
      const newData = [...userData]
      newData[index][field] = previousValue
      setUserData(newData)
      setUndoStack([...undoStack])
    }
  }

  const handleDoubleClick = (index, field) => {
    setEditing({index, field})
    setUndoStack([...undoStack, {index, field, previousValue: userData[index][field]}])
    if (field === 'address') {
      const newData = [...userData]
      newData[index][field] = formatAddress(userData[index][field])
      setUserData(newData)
    }
    setEditedUsers((prev) => new Set(prev).add(userData[index].id))
  }

  const handleChange = (event, index, field) => {
    const newData = [...userData]
    newData[index][field] = event.target.value
    setUserData(newData)
    setEditedUsers((prev) => new Set(prev).add(userData[index].id))
  }

  const handleSaveChanges = async () => {
    let errorMessages = []

    const validUsers = userData.filter((user) => {
      if (editedUsers.has(user.id)) {
        if (!validatePhone(user.phoneNumber)) {
          const errorMessage = `Invalid phone number for user with id ${user.id}: ${user.phoneNumber}`
          errorMessages.push(errorMessage)
          return false
        }

        if (typeof user.address === 'string' && user.address.trim() !== '') {
          if (!user.address.startsWith('{')) {
            const parsedAddress = parseAddress(user.address)
            if (parsedAddress) {
              const addressValidationError = validateAddress(parsedAddress)
              if (addressValidationError) {
                const errorMessage = `Invalid address for user with id ${user.id}: ${addressValidationError}`
                errorMessages.push(errorMessage)
                return false
              }
              user.address = parsedAddress
            } else {
              const errorMessage = `Failed to parse address for user with id ${user.id}: ${user.address}`
              errorMessages.push(errorMessage)
              return false
            }
          }
        }
        return true
      }
      return false
    })

    if (errorMessages.length > 0) {
      setError(errorMessages[0])
      return
    }

    const updatePromises = validUsers.map((user) => updateUser(user, user.id))

    await Promise.allSettled(updatePromises)

    setEditedUsers(new Set())
    await loadUsers()
    setError(null)
  }

  const handleAddRecipients = () => {
    if (selectedUsers.length > 1) {
      const confirmation = window.confirm('Are you sure you want to add recipients to user?')
      if (confirmation) {
        const relatedUserIds = selectedUsers.slice(1).map((user) => user.id)
        const items = [
          {
            userid: selectedUsers[0].id,
            relatedIds: relatedUserIds,
            relationship_type: 'user',
          },
        ]

        addRelated(items, selectedUsers[0].id).then(() => {
          setSelectedUsers([])
          setAddRecipients(false)
          loadUsers()
        })
      }
    } else {
      console.error('At least two users must be selected')
    }
  }

  const groups = ['All', 'Employees', 'Clients', 'Students']

  const toggleDropdown = () => {
    setDropdownState((prevState) => ({
      ...prevState,
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  const handleGroupClick = (group) => {
    setDropdownState({
      selectedGroup: group,
      dropdownOpen: false,
    })
  }

  // modal
  const handleSwitchGroup = () => {
    setGroupModalState({
      showGroupModal: true,
      newGroup: '',
    })
    setFeedback('')
  }

  const handleGroupChange = (event) => {
    if (event && event.target) {
      const {value} = event.target
      setGroupModalState((prevState) => ({
        ...prevState,
        newGroup: value,
      }))
    } else {
      console.error('Invalid event:', event)
    }
  }

  const handleConfirmGroupChange = async () => {
    const newGroup = groupModalState.newGroup.toLowerCase().slice(0, -1)
    console.log('Confirming group change to:', newGroup)

    // Update only the selected users who are not admins and whose group needs to be updated
    const usersToUpdate = selectedUsers.filter((user) => user.group !== 'admin' && user.group.toLowerCase() !== newGroup)
    if (usersToUpdate.length === 0) {
      console.warn('No users need updating.')
      setGroupModalState({showGroupModal: false, newGroup: ''})
      return
    }

    const updatePromises = usersToUpdate.map((user) => updateUser({group: newGroup}, user.id))
    await Promise.all(updatePromises)
    setGroupModalState({showGroupModal: false, newGroup: ''})
    setSelectedUsers([])
    await loadUsers()
  }

  const handleIconClick = (user) => {
    // event.preventDefault()
    // event.stopPropagation()
    setSelectedUserData(user)
    setSelectedUsers([])
  }

  const dataStr = selectedUserData?.name.includes('Allison')
    ? `feed: 08:00 PM L 10 0 0, 10:33 PM  40 0, 12:10 AM  35 0, 02:00 AM  40 0, 07:10 AM  40 0, 07:30 AM  40 0
sleep: 09:15 PM - 10:00 PM, 10:55 PM - 12:03 AM, 12:33 AM - 01:55 AM, 02:35 AM - 04:20 AM, 04:58 AM - 07:10 AM
diapers: 10:30 PM true true, 12:03 AM true false, 01:55 AM true true, 07:10 AM true true`
    : `feed: 08:00 AM R 5 3, 12:00 PM L 4 2
sleep: 09:00 AM - 11:00 AM, 01:00 PM - 02:00 PM
diapers: 07:30 AM true true, 10:30 AM false true`

  // search
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const filteredUserData = userData.filter(
    (user) =>
      (dropdownState.selectedGroup === 'All' || user.group === dropdownState.selectedGroup.toLowerCase().slice(0, -1)) &&
      [user.name, user.email, user.phone, user.address].some((field) => field?.toLowerCase().includes(searchTerm.toLowerCase()))
  )

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='section-header'>
          <h1>People</h1>
        </div>
        <div className='section-body'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header d-flex align-items-center row w-100'>
                  <div className='col'>
                    {!selectedUserData ? (
                      <h4>
                        {dropdownState.selectedGroup.charAt(0).toUpperCase() + dropdownState.selectedGroup.slice(1)}
                        <div className='dropdown d-inline ml-1' ref={dropdownRef}>
                          <a className='font-weight-600' data-toggle='dropdown' href='#' id='groups-dropdown' onClick={toggleDropdown}>
                            <i className={`fas fa-chevron-${dropdownState.dropdownOpen ? 'down' : 'right'}`} />{' '}
                          </a>
                          <ul className={`dropdown-menu dropdown-menu-sm ${dropdownState.dropdownOpen ? 'show' : ''}`}>
                            <li className='dropdown-title'>Select Group</li>
                            {groups.map((group, index) => (
                              <li key={index}>
                                <a href='#' className='dropdown-item' onClick={() => handleGroupClick(group)}>
                                  {group.charAt(0).toUpperCase() + group.slice(1)}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </h4>
                    ) : (
                      <h4>
                        <i className='fas fa-chevron-left mr-1' onClick={() => setSelectedUserData(null)} /> {selectedUserData?.name}
                      </h4>
                    )}
                  </div>

                  <div className='col-auto'>
                    <div className='card-header-form'>
                      {/* <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} /> */}
                    </div>
                  </div>
                </div>
                <div className='input-group-btn d-flex justify-content-end align-items-center mt-2 mb-0' style={{marginRight: '38px'}}>
                  {error && <h6 className='alert alert-danger mt-1 mb-0'>{error}</h6>}
                  {selectedUsers.length > 0 && addRecipients && (
                    <div className='d-flex align-items-center mt-1 mr-2 mb-0'>
                      <h6 className=' me-2 mb-0'>{selectedUsers[0].name}</h6>
                      <i className='fas fa-circle' style={{fontSize: '0.35rem'}}></i>
                    </div>
                  )}
                  {selectedUsers.length > 0 && <h6 className='mt-1 mr-3 mb-0'>{selectedUsers.length} selected</h6>}
                  {undoStack.length > 0 && <i className='fas fa-undo mt-1' onClick={handleUndoClick}></i>}
                </div>
                {!selectedUserData ? (
                  <div className='card-body' style={{...cardBodyStyle, maxHeight: '800px'}}>
                    <table className='table table-hover'>
                      <thead>
                        <tr>
                          <th scope='col'>#</th>
                          <th scope='col'>Image</th>
                          <th scope='col'>Name</th>
                          <th scope='col'>Email</th>
                          <th scope='col'>Phone</th>
                          <th scope='col'>Address</th>
                          <th scope='col'>Status</th>

                          {/* <th scope='col'>Verified</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredUserData.map((user, index) => (
                          <tr
                            key={index}
                            onClick={() => handleRowSelect(user, selectedUsers, setSelectedUsers)}
                            onMouseDown={() => handleMouseDown(setIsDragging)}
                            onMouseUp={() => handleMouseUp(setIsDragging)}
                            onMouseEnter={() => handleMouseEnter(user, isDragging, selectedUsers, setSelectedUsers)}
                            className={selectedUsers.some((e) => e.id === user.id) ? 'table-secondary' : ''}
                          >
                            {/* {console.log(user)} */}
                            <th scope='row'>{index + 1}</th>
                            <td>
                              <img
                                src={
                                  user?.image
                                    ? `data:${user.image.mimetype};base64,${Buffer.from(user.image.data).toString('base64')}`
                                    : 'https://placehold.co/42/000000/FFF?text=Profile'
                                }
                                className='rounded-circle me-2'
                                width='42'
                                height='42'
                              />
                            </td>
                            {['name', 'email', 'phone', 'address'].map((field) => (
                              <td key={field} className='text-nowrap' onDoubleClick={() => handleDoubleClick(index, field)}>
                                {editing.index === index && editing.field === field ? (
                                  <input
                                    type='text'
                                    value={user[field]}
                                    onChange={(e) => handleChange(e, index, field)}
                                    onBlur={() => setEditing({})}
                                    onKeyPress={(e) => e.key === 'Enter' && setEditing({})}
                                    autoFocus
                                  />
                                ) : field === 'address' ? (
                                  formatAddress(user.address)
                                ) : field === 'phone' ? (
                                  formatPhone(user.phone)
                                ) : (
                                  user[field]
                                )}
                              </td>
                            ))}
                            {/* <td>{user.verified ? 'Yes' : 'No'}</td> */}
                            <td>
                              {user.group === 'client' && (
                                <i className='fas fa-info-circle' style={{cursor: 'pointer'}} onClick={(e) => handleIconClick(user)}></i>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>
                    {/* Render another component here that receives selectedUserData */}
                    <div className='row' style={{overflowX: 'auto', overflowY: 'auto'}}>
                      <div className='d-flex d-horizontal'>
                        <ActivitiesInfoCard data={dataStr} title='Activities Info' />
                        <ClientReminders />
                      </div>
                    </div>
                  </div>
                )}
                <div className='card-footer'>
                  <div className='d-flex justify-content-end'>
                    <Button variant='primary' className='mr-2' onClick={handleSwitchGroup}>
                      Switch Groups
                    </Button>
                    <Button variant='primary' className='mr-2' onClick={() => setAddRecipients((prevState) => !prevState)}>
                      {selectedUsers.length === 0 || !addRecipients ? 'Add Recipients' : 'Cancel'}
                    </Button>
                    {((editedUsers.size > 0 && JSON.stringify(userData) !== JSON.stringify(originalData)) || addRecipients) && (
                      <Button variant='success' onClick={addRecipients ? handleAddRecipients : handleSaveChanges}>
                        Save Changes
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={groupModalState.showGroupModal} onHide={() => setGroupModalState({showGroupModal: false, newGroup: ''})} centered>
        <Modal.Header closeButton>
          <Modal.Title>Switch Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Select New Group</Form.Label>
            <Form.Control as='select' value={groupModalState.newGroup} onChange={handleGroupChange}>
              <option value=''>Select Group</option>
              {groups.slice(1).map((group, index) => (
                <option key={index} value={group.toLowerCase()}>
                  {group}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {feedback && <div className='alert alert-warning'>{feedback}</div>}
          <div className='d-flex justify-content-end mt-3'>
            <Button variant='primary' onClick={handleConfirmGroupChange}>
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Users
