import React, {useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {useAuth} from '../../contexts/authContext'
import logo from '../../assets/logo/logo.png'

const Login = () => {
  const {login} = useAuth()
  const history = useHistory()
  const [userData, setUserData] = useState({email: '', password: ''})
  const [message, setMessage] = useState('')

  const handleChange = (e) => {
    const {name, value} = e.target
    setUserData({
      ...userData,
      [name]: value,
    })
  }

  const handleLogin = async (event) => {
    event.preventDefault()
    const {email, password} = userData
    setMessage('')

    const response = await login(email, password)
    response.isAuthenticated ? history.push('/') : setMessage(response.message)
  }

  return (
    <div id='app'>
      <section className='section'>
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3'>
              <div className='login-brand justify-content-center'>
                <img src={logo} alt='Login Logo' className='w-50' style={{margin: '-15%'}} />
              </div>

              <div className='card card-primary mt-n2'>
                <div className='card-header'>
                  <h4>Login</h4>
                </div>

                <div className='card-body'>
                  {message && <div className='alert alert-danger'>{message}</div>}
                  <form method='POST' action='#' noValidate onSubmit={handleLogin}>
                    <div className='form-group'>
                      <label htmlFor='email'>Email</label>
                      <input
                        id='email'
                        type='email'
                        className='form-control'
                        name='email'
                        tabIndex='1'
                        // required
                        autoFocus
                        value={userData.email}
                        onChange={handleChange}
                      />
                      <div className='invalid-feedback'>Please fill in your email</div>
                    </div>

                    <div className='form-group'>
                      <div className='d-block'>
                        <label htmlFor='password' className='control-label'>
                          Password
                        </label>
                        <div className='float-right'>
                          <Link to='/auth/forgot-passw' className='text-small'>
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <input
                        id='password'
                        type='password'
                        className='form-control'
                        name='password'
                        tabIndex='2'
                        // required
                        value={userData.password}
                        onChange={handleChange}
                      />
                      <div className='invalid-feedback'>Please fill in your password</div>
                    </div>

                    <div className='form-group'>
                      <button type='submit' className='btn btn-primary btn-lg btn-block' tabIndex='4'>
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className='mt-5 text-muted text-center'>
                Don't have an account? <Link to='/auth/register'>Create One</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Login
