import React, {useEffect, useState} from 'react'
import {Modal, Form, Button} from 'react-bootstrap'
import {useAuth} from '../../contexts/authContext'
import {getRecipientsByUserId, getDocumentsByUserId, getEventsByUserId, updateEvent, saveDocument} from '../../contexts/service'
import {isInRadius, formatActivities, UserCard, CheckInCard, DocumentPreview, ActivitiesEditCard, handleError} from '../utils'

const DashStaff = () => {
  const {user} = useAuth()
  const [state, setState] = useState({
    recipients: [],
    location: {latitude: null, longitude: null},
    error: null,
    showModal: false,
    document: null,
    event: null,
    info: {
      feed: [],
      sleep: [],
      diapers: [],
    },
    tempRecord: {
      feed: {time: null, nurse: '', feedOz: '', pumpOz: ''},
      sleep: {start: null, end: null},
      diapers: {time: null, wet: false, dirty: false},
    },
  })

  // Destructure state variables
  const {recipients, location, error, showModal, document, event, info, tempRecord} = state

  // Define setError function
  const setError = (message) => {
    setState((prevState) => ({...prevState, error: message}))
  }

  useEffect(() => {
    if (user) {
      fetchData()
    }
  }, [user])

  const fetchData = async () => {
    Promise.all([getRecipientsByUserId(user.id), getDocumentsByUserId(user.id), getEventsByUserId(user.id)])
      .then(([recipientData, documentData, eventResponse]) => {
        const newState = {}

        // Process Recipients
        if (recipientData) {
          newState.recipients = recipientData.filter((recipient) => recipient.name.toLowerCase() !== 'admin')
        }

        // Process Documents
        if (documentData && (documentData.documents || documentData.related)) {
          const combined = [...(documentData.documents || []), ...(documentData.related || [])]
          let latestDocument = null

          for (const doc of combined) {
            if (!latestDocument || new Date(doc.createdAt) > new Date(latestDocument.createdAt)) {
              latestDocument = doc
            }
          }

          if (latestDocument) {
            newState.document = latestDocument
          }
        }

        // Process Events
        if (eventResponse && (eventResponse.events || eventResponse.related)) {
          console.log(eventResponse)
          const relatedEvents = eventResponse.related || []
          const now = new Date()

          const futureRelatedEvents = relatedEvents.filter((event) => new Date(event.end_time) > now)

          if (futureRelatedEvents.length > 0) {
            let nextEvent = null

            for (const event of futureRelatedEvents) {
              if (!nextEvent || new Date(event.end_time) < new Date(nextEvent.end_time)) {
                nextEvent = event
              }
            }

            if (nextEvent) {
              nextEvent.invitees = (nextEvent.invitees || []).filter((invitee) => invitee.user_id === user.id)
              newState.event = nextEvent
            }
          }
        }
        setState((prevState) => ({...prevState, ...newState}))
        console.log(state.event)
      })
      .catch((error) => handleError(setError, error))
  }

  const handleCheckIn = async (checkOut = false) => {
    if ('geolocation' in navigator) {
      const now = new Date()

      console.log('event end time', event?.end_time)
      console.log('current time:', now)

      const tenMinutesBeforeEvent = new Date(event?.start_time)
      if (tenMinutesBeforeEvent) {
        tenMinutesBeforeEvent.setMinutes(tenMinutesBeforeEvent.getMinutes() - 10)
      }

      const eventEndTime = new Date(event?.end_time)

      if (checkOut || (now >= tenMinutesBeforeEvent && now <= eventEndTime)) {
        const useMockLocation = false
        if (useMockLocation) {
          const latitude = 40.66831
          const longitude = -73.61513
          const radius = 0.10668

          setError(null)

          if (event.location) {
            const [eventLat, eventLon] = event.location.position

            if (isInRadius(latitude, longitude, eventLat, eventLon, radius)) {
              setState((prevState) => ({
                ...prevState,
                location: {latitude, longitude},
              }))
              const timeField = checkOut ? 'checkout_time' : 'checkin_time'
              updateEvent(
                {
                  id: event.id,
                  invitees: event.invitees
                    .map((invitee) => (invitee.user_id === user.id ? {...invitee, [timeField]: new Date().toISOString()} : invitee))
                    .filter(Boolean),
                },
                user.id
              )
                .then(() => fetchData())
                .catch((error) => handleError(setError, error))
            } else {
              setError('You are too far from the event')
            }
          }
        } else {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const latitude = position.coords.latitude
              const longitude = position.coords.longitude
              const radius = 0.10668

              setError(null)

              if (event.location) {
                const [eventLat, eventLon] = event.location.position

                if (isInRadius(latitude, longitude, eventLat, eventLon, radius)) {
                  setState((prevState) => ({
                    ...prevState,
                    location: {latitude, longitude},
                  }))
                  const timeField = checkOut ? 'checkout_time' : 'checkin_time'
                  updateEvent(
                    {
                      id: event.id,
                      invitees: event.invitees
                        .map((invitee) => (invitee.user_id === user.id ? {...invitee, [timeField]: new Date().toISOString()} : invitee))
                        .filter(Boolean),
                    },
                    user.id
                  )
                    .then(() => fetchData())
                    .catch((error) => handleError(setError, error))
                } else {
                  setError('You are too far from the event')
                }
              }
            },
            handleGeolocationError,
            {
              enableHighAccuracy: true,
              timeout: 10000,
            }
          )
        }
      } else {
        setError('Check-in is only allowed within 10 minutes of event.')
      }
    } else {
      setError('Geolocation is not supported by your browser.')
    }
  }

  const handleGeolocationError = (error) => {
    let errorMessage
    switch (error.code) {
      case error.PERMISSION_DENIED:
        errorMessage = (
          <span>
            You have denied access to location services.
            <br />
            Please enable location access in your browser settings and click the button again.
          </span>
        )
        break
      case error.POSITION_UNAVAILABLE:
        errorMessage = 'Location information is unavailable. Please check your network or location settings and try again.'
        break
      case error.TIMEOUT:
        errorMessage = 'The request to get user location timed out. Please try again.'
        break
      default:
        errorMessage = 'An unknown error occurred. Please try again.'
    }
    setError(errorMessage)
  }

  const handleShowModal = () => setState((prevState) => ({...prevState, showModal: true}))
  const handleCloseModal = () => setState((prevState) => ({...prevState, showModal: false}))

  const handleInputChange = (type, field, value) => {
    setState((prevState) => ({
      ...prevState,
      tempRecord: {
        ...prevState.tempRecord,
        [type]: {
          ...prevState.tempRecord[type],
          [field]: value,
        },
      },
    }))
  }

  const handleCheckboxChange = (type, field, checked) => {
    setState((prevState) => ({
      ...prevState,
      tempRecord: {
        ...prevState.tempRecord,
        [type]: {
          ...prevState.tempRecord[type],
          [field]: checked,
        },
      },
    }))
  }

  const hasMeaningfulData = (record) => {
    if (record == null) return false
    return Object.values(record).some((value) => {
      if (typeof value === 'boolean') {
        return value === true
      } else {
        return value != null && value !== ''
      }
    })
  }

  const handleAddRecord = (type) => {
    if (hasMeaningfulData(tempRecord[type])) {
      setState((prevState) => ({
        ...prevState,
        info: {
          ...prevState.info,
          [type]: [...prevState.info[type], prevState.tempRecord[type]],
        },
        tempRecord: {
          ...prevState.tempRecord,
          [type]:
            type === 'feed'
              ? {time: null, nurse: '', feedOz: '', pumpOz: ''}
              : type === 'sleep'
              ? {start: null, end: null}
              : {time: null, wet: false, dirty: false},
        },
      }))
    } else {
      // Reset tempRecord[type] even if it's empty
      setState((prevState) => ({
        ...prevState,
        tempRecord: {
          ...prevState.tempRecord,
          [type]:
            type === 'feed'
              ? {time: null, nurse: '', feedOz: '', pumpOz: ''}
              : type === 'sleep'
              ? {start: null, end: null}
              : {time: null, wet: false, dirty: false},
        },
      }))
    }
  }
  const handleNurseClick = () => {
    const options = ['L', 'R', 'LR', '']
    const currentValue = tempRecord.feed.nurse
    const currentIndex = options.indexOf(currentValue)
    const nextIndex = (currentIndex + 1) % options.length
    const nextValue = options[nextIndex]
    handleInputChange('feed', 'nurse', nextValue)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const newInfo = {
      event: event.id,
      feed: hasMeaningfulData(tempRecord.feed) ? [...info.feed, tempRecord.feed] : info.feed,
      sleep: hasMeaningfulData(tempRecord.sleep) ? [...info.sleep, tempRecord.sleep] : info.sleep,
      diapers: hasMeaningfulData(tempRecord.diapers) ? [...info.diapers, tempRecord.diapers] : info.diapers,
    }

    console.log('Submitted data:', newInfo)

    const newInfo2 = formatActivities(newInfo)
    console.log('Formatted data:', newInfo2)

    const formData = new FormData()
    const readableDate = new Date().toISOString().slice(0, 10)
    formData.append('content', JSON.stringify(newInfo2))
    formData.append('name', `Activities ${readableDate}.pdf`)
    formData.append('author', user.name)

    // Console log the formData entries
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`)
    }

    const admin = recipients.find((recipient) => recipient.group === 'admin')

    Promise.all([saveDocument(formData, user.id)])
      .then(() => {
        console.log('Documents saved successfully')

        // Reset info and tempRecord
        setState((prevState) => ({
          ...prevState,
          info: {
            feed: [],
            sleep: [],
            diapers: [],
          },
          tempRecord: {
            feed: {time: null, nurse: '', feedOz: '', pumpOz: ''},
            sleep: {start: null, end: null},
            diapers: {time: null, wet: false, dirty: false},
          },
        }))
      })
      .catch((error) => handleError(setError, error, 'Error saving documents'))
  }

  const handleClear = () => {
    setState((prevState) => ({
      ...prevState,
      tempRecord: {
        feed: {time: '', nurse: '', feedOz: '', pumpOz: ''},
        sleep: {start: '', end: ''},
        diapers: {time: '', wet: false, dirty: false},
      },
      info: {
        feed: [],
        sleep: [],
        diapers: [],
      },
    }))
    console.log('Cleared data')
  }

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='row'>
          <UserCard data={recipients} title='Client Info' />
          <CheckInCard handleCheckIn={handleCheckIn} event={event} location={location} error={error} />
        </div>

        <div className='row'>
          <DocumentPreview name={user.name} document={document} />
          <ActivitiesEditCard
            handleSubmit={handleSubmit}
            handleClear={handleClear}
            info={info}
            tempRecord={tempRecord}
            handleInputChange={handleInputChange}
            handleNurseClick={handleNurseClick}
            handleAddRecord={handleAddRecord}
            handleCheckboxChange={handleCheckboxChange}
          />
        </div>
      </section>
    </div>
  )
}

export default DashStaff
