import config from '../config'

/**
 * helper
 */
const handleRequest = async (url, method, userId, body = null) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...(userId && {userid: userId}),
    },
  }

  if (body && method !== 'GET') {
    options.body = JSON.stringify(body)
  }

  const response = await fetch(url, options)

  if (!response.ok) {
    const errorData = await response.json()
    const errorMessage = errorData.error || response.statusText
    // Create a new Error instance
    const error = new Error(errorMessage)
    error.status = response.status
    throw error
  }

  if (response.status !== 204) {
    return response.json()
  }
  return null
}

/**
 * document endpoints
 */
export const getDocuments = (userId) => {
  return handleRequest(`${config.REACT_URL}/documents`, 'GET', userId)
}

export const getDocumentById = (documentId, userId) => {
  return handleRequest(`${config.REACT_URL}/documents/${documentId}`, 'GET', userId)
}

export const getDocumentsByUserId = (userId) => {
  return handleRequest(`${config.REACT_URL}/documents/user/${userId}`, 'GET', userId)
}

export const saveDocument = async (formData, userId) => {
  const response = await fetch(`${config.REACT_URL}/documents`, {
    method: 'POST',
    headers: {
      userid: userId,
    },
    body: formData,
  })

  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  return response.json()
}

// export const saveDocument = (document, userId) => {
//   return handleRequest(`${config.REACT_URL}/documents`, 'POST', userId, document)
// }

export const deleteDocuments = (documentIds, userId) => {
  const deleteRequests = documentIds.map((documentId) =>
    handleRequest(`${config.REACT_URL}/documents/${documentId}`, 'DELETE', userId).catch((error) => {
      throw new Error(`Failed to delete document with id ${documentId}: ${error.message}`)
    })
  )

  return Promise.all(deleteRequests)
}

/**
 * event endpoints
 */
export const getEvents = (userId) => {
  return handleRequest(`${config.REACT_URL}/events`, 'GET', userId)
}

export const getEventsByUserId = (userId) => {
  return handleRequest(`${config.REACT_URL}/events/user/${userId}`, 'GET', userId)
}

export const createEvent = (event, userId) => {
  return handleRequest(`${config.REACT_URL}/events`, 'POST', userId, event)
}

export const updateEvent = (event, userId) => {
  console.log('updateEvent called with:', {event, userId})
  return handleRequest(`${config.REACT_URL}/events/${event.id}`, 'PUT', userId, event)
}

export const deleteEvents = (eventIds, userId) => {
  const deleteRequests = eventIds.map((eventId) => {
    handleRequest(`${config.REACT_URL}/events/${eventId}`, 'DELETE', userId)
  })

  return Promise.all(deleteRequests)
}

/**
 * user endpoints
 */
export const getUsers = (userId) => {
  return handleRequest(`${config.REACT_URL}/users`, 'GET', userId)
}

export const registerUser = (userData) => {
  return handleRequest(`${config.REACT_URL}/users`, 'POST', null, userData)
}

export const getUserById = (userId) => {
  return handleRequest(`${config.REACT_URL}/users/${userId}`, 'GET', userId)
}

export const updateUser = async (userData, userId) => {
  const url = `${config.REACT_URL}/users/${userId}`

  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      userid: userId,
    },
    body: JSON.stringify(userData),
  }).then(async (response) => {
    if (!response.ok) {
      const error = await response.json()
      throw new Error(error.message || 'Something went wrong!')
    }
    return response.json()
  })
}

// email verification
// export const verifyEmail = async (verificationToken) => {
//   return handleRequest(`/verify-email`, 'POST', null, {token: verificationToken}).catch((error) => {
//     console.error('Error verifying email:', error)
//     throw new Error('Error verifying email. Please try again.')
//   })
// }

export const verifyEmail = async (email, type) => {
  const response = await fetch('/users/verify-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email, type}),
  })

  if (!response.ok) {
    throw new Error('Failed to send email')
  }

  return response.json()
}

/**
 * related endpoints
 */
export const getRecipientsByUserId = (userId) => {
  return handleRequest(`${config.REACT_URL}/related/users/${userId}`, 'GET', userId)
}

export const addRelated = (items, userId) => {
  console.log(items)
  return handleRequest(`${config.REACT_URL}/related`, 'POST', userId, {items})
}

export const deleteRelated = (relatedIds, userId, relationshipType) => {
  return handleRequest(`${config.REACT_URL}/related/${userId}`, 'DELETE', userId, {relatedIds, relationship_type: relationshipType})
}

/**
 * dash endpoints
 */
// export const fetchSignups = async (setSignups, setEntries) => {
//   return fetch('/dash/signups')
//     .then(handleResponse)
//     .then((data) => {
//       if (setSignups) setSignups(data.total_count)
//       if (setEntries) setEntries(data.entries)
//     })
//     .catch((error) => console.error('Failed to fetch signups:', error))
// }

// export const deleteSignups = async (itemIds) => {
//   return fetch('/dash/signups', {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({itemIds}),
//   })
//     .then(handleResponse)
//     .catch((error) => console.error('Failed to delete signups:', error))
// }

// export const fetchOrders = async (setOrders) => {
//   return fetch('/dash/orders')
//     .then(handleResponse)
//     .then((data) => {
//       if (setOrders) setOrders(data)
//     })
//     .catch((error) => console.error('Failed to fetch orders:', error))
// }

// export const deleteOrders = async (itemIds) => {
//   return fetch('/dash/orders', {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({itemIds}),
//   })
//     .then(handleResponse)
//     .catch((error) => console.error('Failed to delete orders:', error))
// }

/**
 * dash endpoints
 */

export const fetchSignups = async (setSignups = null, setEntries = null) => {
  const url = `${config.GOPHER_URL}/wp-json/gf/v2/forms/3/entries`
  const username = config.SIGNUP_USER
  const password = config.SIGNUP_PASS

  const params = new URLSearchParams({
    'paging[page_size]': 30,
  })

  const response = await fetch(`${url}?${params.toString()}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa(`${username}:${password}`),
    },
  })

  if (response && response.ok) {
    const data = await response.json()
    if (setSignups) {
      setSignups(data.total_count)
    }
    if (setEntries) {
      setEntries(data.entries)
    }
  } else {
    if (response) {
      const errorData = await response.json()
      console.error('Failed to fetch data:', errorData)
    }
  }
}

export const deleteSignups = async (itemIds) => {
  const url = `${config.GOPHER_URL}/wp-json/gf/v2/entries`
  const username = config.SIGNUP_USER
  const password = config.SIGNUP_PASS

  const deletePromises = itemIds.map(async (itemId) => {
    const deleteUrl = `${url}/${itemId}`
    const response = await fetch(deleteUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(`${username}:${password}`),
      },
    })

    if (!response.ok) {
      const errorData = await response.json()
      console.error(`Failed to delete item with ID ${itemId}:`, errorData)
    }
  })

  await Promise.all(deletePromises)
}

export const fetchOrders = async (setOrders = null) => {
  const url = `${config.GOPHER_URL}/wp-json/wc/v3/orders`
  const username = config.ORDER_USER
  const password = config.ORDER_PASS

  const response = await fetch(`${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa(`${username}:${password}`),
    },
  })

  if (response && response.ok) {
    const data = await response.json()
    setOrders(data)
  } else {
    if (response) {
      const errorData = await response.json()
      console.error('Failed to fetch data:', errorData)
    }
  }
}

export const deleteOrders = async (itemIds) => {
  const url = `${config.GOPHER_URL}/wp-json/wc/v3/orders`
  const username = config.ORDER_USER
  const password = config.ORDER_PASS

  const deletePromises = itemIds.map(async (itemId) => {
    const deleteUrl = `${url}/${itemId}`
    const response = await fetch(deleteUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(`${username}:${password}`),
      },
    })

    if (!response.ok) {
      const errorData = await response.json()
      console.error(`Failed to delete order with ID ${itemId}:`, errorData)
    }
  })

  await Promise.all(deletePromises)
}

// utils
export const fetchLocationPosition = async (location) => {
  return fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(location)}&format=json&limit=1`)
    .then((response) => response.json())
    .then((data) => {
      if (data && data.length > 0) {
        return [parseFloat(data[0].lat), parseFloat(data[0].lon)]
      }
      return []
    })
    .catch((error) => {
      console.error('Error fetching position for the address:', error)
      return []
    })
}
