import React from 'react'

// Helper function to convert a value to a string with memoization
const valueToString = (() => {
  const cache = new Map()
  const weakCache = new WeakMap()

  const helper = (value) => {
    if (typeof value === 'object' && value !== null) {
      if (weakCache.has(value)) {
        return weakCache.get(value)
      }

      let result
      if (value instanceof Date) {
        result = `${value.toLocaleDateString()} ${value.toLocaleTimeString()}`
      } else {
        result = Object.values(value)
          .map((subValue) => helper(subValue))
          .join(' ')
      }

      weakCache.set(value, result)
      return result
    } else {
      if (cache.has(value)) {
        return cache.get(value)
      }

      const result = String(value)
      cache.set(value, result)
      return result
    }
  }

  return helper
})()

// Convert entire object to string once and cache results
const objectToString = (() => {
  const cache = new WeakMap()

  return (obj) => {
    if (cache.has(obj)) {
      return cache.get(obj)
    }

    const result = Object.values(obj)
      .map((value) => valueToString(value))
      .join(' ')

    cache.set(obj, result)
    return result
  }
})()

// Filter data based on a search term
export const getFilteredData = (data = [], searchTerm = '') => {
  if (!Array.isArray(data)) {
    return []
  }
  const normalizedSearchTerm = searchTerm.toLowerCase()
  return data.filter((item) =>
    objectToString(item)
      .toLowerCase()
      .includes(normalizedSearchTerm)
  )
}

export const SearchBar = ({searchTerm, onSearchChange}) => {
  return (
    <form className='search-bar-container mb-0'>
      <div className='input-group'>
        <input type='text' className='form-control' value={searchTerm} onChange={(e) => onSearchChange(e.target.value)} />
        <i className='fas fa-search mt-0 mb-0' style={{fontSize: '1.2rem'}}></i>
      </div>
    </form>
  )
}
