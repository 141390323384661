import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import {Data} from './data'
import {useAuth} from '../../contexts/authContext'
import logo from '../../assets/logo/logo.png'
import logosmall from '../../assets/logo/logosmall.png'

import '../../js/js/scripts'
import '../../js/js/grey'

export const SideBar = () => {
  const {user} = useAuth()

  const handleCallUs = (phoneNumber) => {
    const confirmCall = window.confirm(`Would you like to call us at ${phoneNumber}?`)
    if (confirmCall) {
      // Optional: Redirect to phone dialer on supported devices
      window.location.href = `tel:${phoneNumber}`
    }
  }

  return (
    <div className='main-sidebar'>
      <aside id='sidebar-wrapper'>
        <div className='sidebar-brand mt-n4'>
          <Link to='/'>
            {' '}
            {/* Added the 'to' prop */}
            <img
              src={logo}
              alt='Login Logo'
              style={{
                margin: '-20%',
                marginTop: '-10%', // Slight adjustment to decrease negative margin
                paddingLeft: '-18%', // Slight adjustment to decrease negative padding
                width: '110%', // Slightly increased width
                height: 'auto', // Maintain aspect ratio
              }}
            />
          </Link>
        </div>
        <div className='sidebar-brand sidebar-brand-sm'>
          <img src={logosmall} alt='Login Logo' className='mt-4 w-100' />
        </div>
        <ul className='sidebar-menu mt-5'>
          {Data.menus.map((menu, iMenu) => {
            // Conditionally render the Users menu item for admin only
            if (menu.url === '/users' && user?.group !== 'admin') {
              return null
            }

            if (menu.url === '/editor' && user?.group === 'client') {
              menu.name = 'Requests'
            }

            let comp
            if (menu.header) {
              comp = (
                <li key={iMenu} className='menu-header'>
                  {menu.name}
                </li>
              )
            } else if (menu.dropdown) {
              comp = (
                <li key={iMenu} className={`nav-item dropdown ${menu.active ? 'active' : ''}`}>
                  <a href='#' className='nav-link has-dropdown'>
                    <i className={menu.icon} /> <span> {menu.name} </span>
                  </a>
                  <ul className='dropdown-menu'>
                    {menu.children.map((submenu, iSubmenu) => (
                      <li key={iSubmenu} className={submenu.active ? 'active' : ''}>
                        <NavLink activeStyle={{color: ' #fff', fontWeight: '600'}} exact to={submenu.url}>
                          {submenu.beep ? <span className='beep beep-sidebar'>{submenu.name}</span> : submenu.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              )
            } else {
              comp = (
                <li key={iMenu} className={menu.active ? 'active' : ''}>
                  <NavLink activeStyle={{color: ' #fff', fontWeight: '600'}} exact to={menu.url}>
                    <i className={menu.icon} /> <span> {menu.name} </span>
                  </NavLink>
                </li>
              )
            }

            return comp
          })}
        </ul>

        <div className='sidebar-footer mt-auto'>
          <ul className='sidebar-menu'>
            {Data.footerMenus.map((footerMenu, iFooter) => {
              if (footerMenu.icon.includes('fas fa-phone')) {
                return (
                  <li key={iFooter}>
                    <a
                      href='#'
                      className='nav-link'
                      onClick={(e) => {
                        e.preventDefault()
                        handleCallUs(footerMenu.phoneNumber)
                      }}
                    >
                      <i className={footerMenu.icon}></i> <span> {footerMenu.name} </span>
                    </a>
                  </li>
                )
              }

              // Add more footer menu items here if needed

              return null
            })}
          </ul>
        </div>
      </aside>
    </div>
  )
}

export default SideBar
