import React, {useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {verifyEmail} from '../../contexts/service'
import logo from '../../assets/logo/logo.png'

const VerifyEmail = () => {
  const history = useHistory()
  const location = useLocation()
  const [message, setMessage] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [actionType, setActionType] = useState('verifyEmail')

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const token = urlParams.get('token')
    const email = urlParams.get('email')
    const type = urlParams.get('type')

    if (type === 'resetPassword') {
      setActionType('resetPassword')
    }

    if (token && email && type === 'verifyEmail') {
      verifyEmail(email, token, 'verifyEmail')
        .then((data) => {
          if (data.success) {
            setMessage('Email verified successfully!')
            setTimeout(() => {
              history.push('/')
            }, 3000)
          } else {
            setMessage(data.message || 'Invalid verification link.')
          }
        })
        .catch(() => {
          setMessage('Error verifying email. Please try again.')
        })
    }
  }, [history, location.search])

  const handlePasswordReset = (e) => {
    e.preventDefault()
    const urlParams = new URLSearchParams(location.search)
    const token = urlParams.get('token')
    const email = urlParams.get('email')

    if (password !== confirmPassword) {
      setMessage('Passwords do not match.')
      return
    }

    verifyEmail(email, token, 'resetPassword', password)
      .then((data) => {
        if (data.success) {
          setMessage('Password reset successfully.')
          setTimeout(() => {
            history.push('/login')
          }, 3000)
        } else {
          setMessage(data.message || 'Failed to reset password.')
        }
      })
      .catch(() => {
        setMessage('Error resetting password. Please try again.')
      })
  }

  return (
    <div id='app'>
      <section className='section'>
        <div className='container mt-5'>
          <div className='row'>
            <div className='col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2'>
              <div className='login-brand'>
                <img src={logo} alt='logo' className='w-50' style={{margin: '-10%'}} />
              </div>
              <div className='card card-primary'>
                <div className='card-header'>
                  <h4>{actionType === 'resetPassword' ? 'Reset Password' : 'Email Verification'}</h4>
                </div>
                <div className='card-body mt-3'>
                  {actionType === 'resetPassword' ? (
                    <form onSubmit={handlePasswordReset}>
                      <div className='form-group'>
                        <label htmlFor='password'>New Password</label>
                        <input
                          id='password'
                          type='password'
                          className='form-control'
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          tabIndex='1'
                          required
                        />
                      </div>
                      <div className='form-group'>
                        <label htmlFor='confirmPassword'>Confirm New Password</label>
                        <input
                          id='confirmPassword'
                          type='password'
                          className='form-control'
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          tabIndex='2'
                          required
                        />
                      </div>
                      <div className='form-group'>
                        <button type='submit' className='btn btn-primary btn-lg btn-block' tabIndex='4'>
                          Reset Password
                        </button>
                      </div>
                    </form>
                  ) : (
                    <div>{message && <p>{message}</p>}</div>
                  )}
                  {message && <p>{message}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default VerifyEmail
