import React from 'react'
import {Switch, Route, Redirect, useLocation} from 'react-router-dom'
import {Header, Footer, Sidebar} from './layout'
import {useAuth} from './contexts/authContext'
import {Dashboard, DashClient, DashStaff, DashStudent, ShopData, SignupData} from './pages/dash'
import {Calendar, Documents, Users, Editor, Login, Register, VerifyEmail, ForgotPassword, ResetPassword, Error} from './pages'

function App() {
  const locationParent = useLocation().pathname.split('/')[1]
  const withoutRouter = ['auth', 'error', 'utilities']
  const showHeaderSidebar = !withoutRouter.includes(locationParent)

  const {isAuthenticated, user} = useAuth()

  console.log('isAuth:', isAuthenticated, user?.group)

  return (
    <div className='App'>
      {showHeaderSidebar && (
        <>
          <Header />
          <Sidebar />
        </>
      )}
      <React.Suspense fallback={<h1>Loading...</h1>}>
        <Switch>
          {isAuthenticated ? (
            <>
              <Route exact path='/'>
                {user?.group === 'admin' && <Dashboard />}
                {user?.group === 'client' && <DashClient user={user} />}
                {user?.group === 'employee' && <DashStaff user={user} />}
                {user?.group === 'student' && <DashStudent user={user} />}
                {!user?.group && <Redirect to='/auth/login' />}
              </Route>
              <Route path='/shop-data' component={ShopData} />
              <Route path='/signup-data' component={SignupData} />
              {user?.group === 'admin' && <Route path='/users' component={Users} />}
              <Route path='/calendar' component={Calendar} />
              <Route path='/documents' component={Documents} />
              <Route path='/editor' component={Editor} />
            </>
          ) : (
            <>
              <Route path='/auth/login' component={Login} />
              <Route path='/auth/forgot-passw' component={ForgotPassword} />
              <Route path='/auth/reset-passw' component={ResetPassword} />
              <Route path='/auth/register' component={Register} />
              <Route path='/auth/verify-email' component={VerifyEmail} />
              <Redirect to='/auth/login' />
            </>
          )}
          <Route path='/error/403' component={() => <Error errorCode='403' errorMessage='You do not have access to this page.' />} />
          <Route path='/error/404' component={() => <Error errorCode='404' errorMessage='Page not be found.' />} />
          <Route path='/error/500' component={() => <Error errorCode='500' errorMessage='Something went wrong.' />} />
          <Route path='/error/503' component={() => <Error errorCode='503' errorMessage='Be right back.' />} />
          <Redirect to='/error/404' />
        </Switch>
      </React.Suspense>
      <Footer />
    </div>
  )
}

export default App
