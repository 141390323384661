import React from 'react'
import {Modal, Button, Form, ListGroup} from 'react-bootstrap'
import {Map, Marker} from 'pigeon-maps'
import {frequencyMap, daysOfWeek, getFilteredData} from './'

export const CalendarModal = ({
  handleCloseModal,
  handleSubmit,
  handleInputChange,
  setModalState,
  handleSuggestionSelect,
  handleRRuleChange,
  handleDayOfWeekChange,
  toggleIntervalVisibility,
  handleInviteeChange,
  handleDeleteClick,
  setShareSearch,
  setSelectedRecipients,
  handleShare,
  handleDeleteSelected,
  handleUpdateSelected,
  modalState,
  user,
  recipients,
  selectedEvents,
  selectedRecipients,
  shareSearch,
}) => {
  return (
    <>
      {modalState.show && modalState.type === 'edit' && user.group === 'admin' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{modalState.isEditing ? 'Edit Event' : 'Add Event'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className='mb-3'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  name='name'
                  value={modalState.content.name}
                  onChange={handleInputChange}
                  placeholder='Enter event name'
                  required
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Details</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={4}
                  name='details'
                  value={modalState.content.details}
                  onChange={handleInputChange}
                  placeholder='Enter event details'
                  style={{minHeight: '120px'}}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Starts</Form.Label>
                <div className='d-flex'>
                  <Form.Control
                    type='date'
                    name='startDate'
                    value={modalState.content.start_date || ''}
                    onChange={handleInputChange}
                    required
                    className='mr-2'
                  />
                  <Form.Control type='time' name='start_time' value={modalState.content.start_time} onChange={handleInputChange} required />
                </div>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Ends</Form.Label>
                <div className='d-flex'>
                  <Form.Control
                    type='date'
                    name='endDate'
                    value={modalState.content.end_date || ''}
                    onChange={handleInputChange}
                    required
                    className='mr-2'
                  />
                  <Form.Control type='time' name='end_time' value={modalState.content.end_time} onChange={handleInputChange} required />
                </div>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Check
                  type='checkbox'
                  label='Location'
                  checked={!!modalState.location}
                  onChange={(e) => {
                    const checked = e.target.checked
                    setModalState((prevState) => ({
                      ...prevState,
                      location: checked,
                      content: {
                        ...prevState.content,
                        location: checked ? {address: '', position: []} : null,
                        suggestions: [],
                      },
                      markerPosition: checked ? [40.776676, -73.971321] : [],
                    }))
                  }}
                />
              </Form.Group>
              {modalState.location && (
                <>
                  <div className='rounded-3 overflow-hidden mb-3'>
                    <Map
                      height={400}
                      center={
                        modalState.content.location &&
                        Array.isArray(modalState.content.location.position) &&
                        modalState.content.location.position.length === 2
                          ? modalState.content.location.position
                          : [40.776676, -73.971321]
                      }
                      defaultZoom={11}
                    >
                      <Marker
                        width={50}
                        anchor={
                          modalState.content.location &&
                          Array.isArray(modalState.content.location.position) &&
                          modalState.content.location.position.length === 2
                            ? modalState.content.location.position
                            : [40.776676, -73.971321]
                        }
                      />
                    </Map>
                  </div>

                  <Form.Group className='mb-3'>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type='text'
                      name='location'
                      value={modalState.content.location && modalState.content.location.address}
                      onChange={handleInputChange}
                      placeholder='Enter event address'
                      required
                    />
                  </Form.Group>
                  {modalState.suggestions && (
                    <ListGroup>
                      {modalState.suggestions.map((suggestion, index) => (
                        <ListGroup.Item key={index} onClick={() => handleSuggestionSelect(suggestion)}>
                          {suggestion.display_name}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </>
              )}
              <Form.Group className='mb-3'>
                <Form.Check
                  type='checkbox'
                  label='Repeat'
                  checked={!!modalState.content.rrule}
                  onChange={(e) => {
                    const checked = e.target.checked
                    setModalState((prevState) => ({
                      ...prevState,
                      content: {
                        ...prevState.content,
                        rrule: checked ? 'FREQ=DAILY' : '',
                        rruleObj: checked ? {FREQ: 'DAILY', INTERVAL: '1', UNTIL: '', BYDAY: []} : {},
                      },
                    }))
                  }}
                />
              </Form.Group>
              {modalState.content.rrule && (
                <>
                  <Form.Group className='mb-3'>
                    <div className='d-flex align-items-center flex-nowrap'>
                      <span className='me-2'>Every</span>

                      {modalState.content.rruleObj.INTERVAL > 1 && (
                        <Form.Control
                          type='number'
                          name='INTERVAL'
                          value={modalState.content.rruleObj.INTERVAL || 1}
                          onChange={handleRRuleChange}
                          className='me-2 flex-shrink-0'
                          style={{width: 'auto'}}
                          min={1}
                          max={99}
                          required
                        />
                      )}

                      <Form.Control
                        as='select'
                        name='FREQ'
                        value={modalState.content.rruleObj.FREQ || 'DAILY'}
                        onChange={handleRRuleChange}
                        className='me-2 flex-shrink-0'
                        style={{width: 'auto'}}
                        required
                      >
                        {[
                          {value: 'DAILY', label: 'day'},
                          {value: 'WEEKLY', label: 'week'},
                          {value: 'MONTHLY', label: 'month'},
                          {value: 'YEARLY', label: 'year'},
                        ].map((freq) => (
                          <option key={freq.value} value={freq.value}>
                            {modalState.content.rruleObj.INTERVAL > 1 ? `${freq.label}s` : freq.label}
                          </option>
                        ))}
                      </Form.Control>

                      <span className='me-2'>until</span>

                      <Form.Control
                        type='date'
                        name='UNTIL'
                        value={modalState.content.rruleObj.UNTIL || ''}
                        onChange={handleRRuleChange}
                        className='flex-grow-1'
                        style={{maxWidth: '300px'}}
                        required
                      />
                    </div>

                    {modalState.content.rruleObj.FREQ === 'WEEKLY' && (
                      <div className='d-flex align-items-center flex-nowrap mt-3'>
                        <span className='me-2'>On</span>
                        <div className='d-flex align-items-center gap-1'>
                          {['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'].map((day, index) => (
                            <Form.Check
                              key={index}
                              inline
                              type='checkbox'
                              label={day}
                              value={day}
                              checked={modalState.content.rruleObj.BYDAY?.includes(day) || false}
                              onChange={handleDayOfWeekChange}
                              className='mb-n1'
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    <div className='mt-2'>
                      <span variant='secondary' onClick={toggleIntervalVisibility}>
                        {modalState.content.rruleObj.INTERVAL > 1 ? 'Hide Interval' : 'Change Interval'}
                      </span>
                    </div>
                  </Form.Group>
                </>
              )}
              <Form.Group className='mb-3'>
                <Form.Label>Assign</Form.Label>
                <Form.Select
                  value=''
                  onChange={(e) => {
                    const selectedInviteeId = e.target.value
                    if (selectedInviteeId) {
                      handleInviteeChange(selectedInviteeId, 'assign')
                    }
                  }}
                >
                  <option value='' disabled>
                    Select an invitee
                  </option>
                  {recipients
                    .filter((recipient) => recipient.group === 'employee')
                    .map((filteredRecipient) => (
                      <option key={filteredRecipient.id} value={filteredRecipient.id}>
                        {filteredRecipient.name}
                      </option>
                    ))}
                </Form.Select>

                <div className='mt-2'>
                  {(modalState.content.invitees || [])
                    .filter((invitee) => !invitee.deleted)
                    .map((invitee, index) => (
                      <button
                        key={index}
                        type='button'
                        className='btn btn-primary btn-sm rounded-pill my-1 me-2 align-items-center justify-content-center'
                        onClick={() => handleInviteeChange(invitee.user_id, 'remove')}
                      >
                        <i className='fas fa-times me-1' />
                        {invitee.user}
                      </button>
                    ))}
                </div>
              </Form.Group>

              <div className='d-flex justify-content-end mt-3'>
                {modalState.isEditing && (
                  <Button variant='danger' onClick={handleDeleteClick} className='mr-2'>
                    Delete
                  </Button>
                )}
                <Button variant='primary' type='submit'>
                  {modalState.isEditing ? 'Save Event' : 'Add Event'}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
      {modalState.show && modalState.type === 'view' && user.group !== 'admin' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{'View Event'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-3'>
              <strong className='fs-6'>Name</strong>
              <p className='fs-6'>{modalState.content.name}</p>
            </div>
            <div className='mb-3'>
              <strong className='fs-6'>Details</strong>
              <p className='fs-6'>{modalState.content.details}</p>
            </div>
            <div className='d-flex justify-content-start column mb-1'>
              <div className='d-flex flex-column me-5'>
                <strong className='fs-6'>Starts</strong>
                <p className='fs-6'>{modalState.content.start_time}</p>
              </div>
              <div className='d-flex flex-column'>
                <strong className='fs-6'>Ends</strong>
                <p className='fs-6'>{modalState.content.end_time}</p>
              </div>
            </div>
            {modalState.location && (
              <div className='mb-3'>
                <strong className='fs-6'>Location</strong>
                <div className='rounded-3 overflow-hidden mt-2 mb-2'>
                  <Map
                    height={400}
                    center={(modalState.content.location && modalState.content.location.position) || [40.776676, -73.971321]}
                    defaultZoom={11}
                  >
                    <Marker
                      width={50}
                      anchor={(modalState.content.location && modalState.content.location.position) || [40.776676, -73.971321]}
                    />
                  </Map>
                </div>
                <div className='mb-3'>
                  <strong className='fs-6'>Address</strong>
                  <p className='fs-6'>{modalState.content.location?.address}</p>
                </div>
              </div>
            )}
            {modalState.content.rrule && (
              <div className='mb-3'>
                <strong className='fs-6'>Repeats</strong>
                <p className='fs-6'>
                  {`This event occurs every ${
                    modalState.content.rruleObj.INTERVAL > 1
                      ? `${modalState.content.rruleObj.INTERVAL} ${frequencyMap[modalState.content.rruleObj.FREQ]}s`
                      : frequencyMap[modalState.content.rruleObj.FREQ]
                  }`}
                  {modalState.content.rruleObj.FREQ === 'WEEKLY' && ` on ${daysOfWeek(modalState.content.rruleObj.BYDAY)}`}
                  {` until ${new Date(modalState.content.rruleObj.UNTIL).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}.`}
                </p>
              </div>
            )}
            {modalState.content.invitees && (
              <div className='mb-3'>
                <strong className='fs-6'>Assigned</strong>

                <div className='mt-2'>
                  {(modalState.content.invitees || []).map((invitee, index) => (
                    <div className='d-flex align-items-center' key={index}>
                      <i className='fas fa-circle me-2 mb-n1' style={{fontSize: '0.35rem'}}></i>
                      <p className='mb-n1'>{invitee.user}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}
      {modalState.show && modalState.type === 'share' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered size='sm'>
          <Modal.Header closeButton>
            <Modal.Title>{`Share ${selectedEvents.length > 1 ? `${selectedEvents.length} Events` : 'Event'}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId='formRecipients'>
                <Form.Label>Recipients {selectedRecipients.length > 0 && `+${selectedRecipients.length} selected`}</Form.Label>
              </Form.Group>
              <Form.Group controlId='formSearch'>
                <Form.Control
                  type='text'
                  placeholder='Search Recipients'
                  value={shareSearch}
                  onChange={(e) => setShareSearch(e.target.value)}
                  className='mb-2'
                />
              </Form.Group>
              <div
                style={{
                  height: '220px',
                  overflowY: 'auto',
                  display: user.group === 'admin' ? 'flex' : 'block', // Flex layout for admin, block for others
                  gap: user.group === 'admin' ? '20px' : '0', // Gap between columns for admin
                }}
              >
                {user.group === 'admin' ? (
                  ['employee', 'client'].map((group) => {
                    const filteredGroup = getFilteredData(recipients, shareSearch)
                      .filter((recipient) => recipient.group === group)
                      .sort((a, b) => {
                        const aSelected = selectedRecipients.includes(a.id)
                        const bSelected = selectedRecipients.includes(b.id)
                        if (aSelected === bSelected) return 0
                        return aSelected ? -1 : 1
                      })

                    if (filteredGroup.length === 0) return null // Skip rendering if no recipients in group

                    // Capitalize the first letter of the group name for the header
                    const groupName = group.charAt(0).toUpperCase() + group.slice(1)

                    return (
                      <div key={group} style={{flex: 1}}>
                        <h5>{groupName}s</h5>
                        {filteredGroup.map((recipient) => {
                          const isSelected = selectedRecipients.includes(recipient.id)
                          return (
                            <div
                              key={recipient.id}
                              className='fs-6 mb-2'
                              onClick={() => {
                                if (isSelected) {
                                  setSelectedRecipients(selectedRecipients.filter((id) => id !== recipient.id))
                                } else {
                                  setSelectedRecipients([...selectedRecipients, recipient.id])
                                }
                              }}
                              style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between', // Aligns the icon to the right
                              }}
                            >
                              {recipient.name}
                              {isSelected && <i className='fas fa-times text-danger ml-2'></i>}
                            </div>
                          )
                        })}
                      </div>
                    )
                  })
                ) : (
                  // Rendering for non-admin users
                  <div style={{flex: 1}}>
                    <h5>Recipients</h5>
                    {getFilteredData(recipients, shareSearch)
                      .sort((a, b) => {
                        const aSelected = selectedRecipients.includes(a.id)
                        const bSelected = selectedRecipients.includes(b.id)
                        if (aSelected === bSelected) return 0
                        return aSelected ? -1 : 1
                      })
                      .map((recipient) => {
                        const isSelected = selectedRecipients.includes(recipient.id)
                        return (
                          <div
                            key={recipient.id}
                            className='fs-6 mb-2'
                            onClick={() => {
                              if (isSelected) {
                                setSelectedRecipients(selectedRecipients.filter((id) => id !== recipient.id))
                              } else {
                                setSelectedRecipients([...selectedRecipients, recipient.id])
                              }
                            }}
                            style={{
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            {recipient.name}
                            {isSelected && <i className='fas fa-times text-danger ml-2'></i>}
                          </div>
                        )
                      })}
                  </div>
                )}
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='primary'
              onClick={() => handleShare(selectedRecipients)}
              disabled={selectedEvents.length < 1 || selectedRecipients.length < 1}
            >
              Share
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {modalState.show && modalState.type === 'delete' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Event</Modal.Title>
          </Modal.Header>
          <Modal.Body className='d-flex flex-column align-items-center'>
            <Button
              variant='secondary'
              size='lg'
              className='mb-3 w-100'
              onClick={() => {
                setModalState({...modalState, deletionMode: 'one'})
                handleDeleteSelected('one')
              }}
            >
              Delete This Event
            </Button>
            <Button
              variant='danger'
              size='lg'
              className='w-100'
              onClick={() => {
                setModalState({...modalState, deletionMode: 'all'})
                handleDeleteSelected('all')
              }}
            >
              Delete All Future Events
            </Button>
          </Modal.Body>
        </Modal>
      )}
      {modalState.show && modalState.type === 'update' && (
        <Modal show={modalState.show} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Update Event</Modal.Title>
          </Modal.Header>
          <Modal.Body className='d-flex flex-column align-items-center'>
            <Button
              variant='primary'
              size='lg'
              className='mb-3 w-100'
              onClick={() => {
                setModalState({...modalState, updateMode: 'one'})
                handleUpdateSelected('one')
              }}
            >
              Update This Event
            </Button>
            <Button
              variant='primary'
              size='lg'
              className='w-100'
              onClick={() => {
                setModalState({...modalState, updateMode: 'all'})
                handleUpdateSelected('all')
              }}
            >
              Update All Future Events
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export const DocumentModal = ({
  user,
  modalState,
  handleCloseModal,
  selectedDocuments,
  selectedRecipients,
  shareSearch,
  setShareSearch,
  getFilteredData,
  recipients,
  setSelectedRecipients,
  handleShare,
}) => {
  // If the modal should not be shown, render nothing
  if (!modalState.show) {
    return null
  }

  // Render the 'view' type Modal
  if (modalState.type === 'view' && modalState.content) {
    return (
      <Modal show={modalState.show} onHide={handleCloseModal} centered size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{modalState.content.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={`data:${modalState.content.mime_type};base64,${Buffer.from(modalState.content.content).toString('base64')}`}
            frameBorder='0'
            width='100%'
            height='600px'
            title={modalState.content.name}
          ></iframe>
        </Modal.Body>
      </Modal>
    )
  }

  // Render the 'share' type Modal
  if (modalState.type === 'share') {
    return (
      <Modal show={modalState.show} onHide={handleCloseModal} centered size='sm'>
        <Modal.Header closeButton>
          <Modal.Title>{`Share ${selectedDocuments.length < 2 ? 'Document' : `${selectedDocuments.length} Documents`}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Recipients Label */}
            <Form.Group controlId='formRecipients'>
              <Form.Label>Recipients {selectedRecipients.length > 0 && `+${selectedRecipients.length} selected`}</Form.Label>
            </Form.Group>

            {/* Search Recipients */}
            <Form.Group controlId='formSearch'>
              <Form.Control
                type='text'
                placeholder='Search Recipients'
                value={shareSearch}
                onChange={(e) => setShareSearch(e.target.value)}
                className='mb-2'
              />
            </Form.Group>

            {/* Recipients List */}
            <div
              style={{
                height: '220px',
                overflowY: 'auto',
                display: user.group === 'admin' ? 'flex' : 'block', // Flex layout for admin, block for others
                gap: user.group === 'admin' ? '20px' : '0', // Gap between columns for admin
              }}
            >
              {user.group === 'admin' ? (
                ['employee', 'client'].map((group) => {
                  const filteredGroup = getFilteredData(recipients, shareSearch)
                    .filter((recipient) => recipient.group === group)
                    .sort((a, b) => {
                      const aSelected = selectedRecipients.includes(a.id)
                      const bSelected = selectedRecipients.includes(b.id)
                      if (aSelected === bSelected) return 0
                      return aSelected ? -1 : 1
                    })

                  if (filteredGroup.length === 0) return null // Skip rendering if no recipients in group

                  // Capitalize the first letter of the group name for the header
                  const groupName = group.charAt(0).toUpperCase() + group.slice(1)

                  return (
                    <div key={group} style={{flex: 1}}>
                      <h5>{groupName}s</h5>
                      {filteredGroup.map((recipient) => {
                        const isSelected = selectedRecipients.includes(recipient.id)
                        return (
                          <div
                            key={recipient.id}
                            className='fs-6 mb-2'
                            onClick={() => {
                              if (isSelected) {
                                setSelectedRecipients(selectedRecipients.filter((id) => id !== recipient.id))
                              } else {
                                setSelectedRecipients([...selectedRecipients, recipient.id])
                              }
                            }}
                            style={{
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between', // Aligns the icon to the right
                            }}
                          >
                            <span>{recipient.name}</span>
                            {isSelected && <i className='fas fa-times text-danger ml-2'></i>}
                          </div>
                        )
                      })}
                    </div>
                  )
                })
              ) : (
                // Rendering for non-admin users
                <div style={{flex: 1}}>
                  <h5>Recipients</h5>
                  {getFilteredData(recipients, shareSearch)
                    .sort((a, b) => {
                      const aSelected = selectedRecipients.includes(a.id)
                      const bSelected = selectedRecipients.includes(b.id)
                      if (aSelected === bSelected) return 0
                      return aSelected ? -1 : 1
                    })
                    .map((recipient) => {
                      const isSelected = selectedRecipients.includes(recipient.id)
                      return (
                        <div
                          key={recipient.id}
                          className='fs-6 mb-2'
                          onClick={() => {
                            if (isSelected) {
                              setSelectedRecipients(selectedRecipients.filter((id) => id !== recipient.id))
                            } else {
                              setSelectedRecipients([...selectedRecipients, recipient.id])
                            }
                          }}
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span>{recipient.name}</span>
                          {isSelected && <i className='fas fa-times text-danger ml-2'></i>}
                        </div>
                      )
                    })}
                </div>
              )}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => handleShare(selectedRecipients)}
            disabled={selectedDocuments.length < 1 || selectedRecipients.length < 1}
          >
            Share
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  // If modalState.type doesn't match any known type, render nothing
  return null
}
