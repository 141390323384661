import React, {useState, useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import {useAuth} from '../../contexts/authContext'
import {Modal, Button, Form, Dropdown, Alert} from 'react-bootstrap'
import {formatAddress, handleError} from '../../pages/utils'
import {updateUser} from '../../contexts/service'

// Image Compression Function Using Canvas (No Dependencies)
const compressImage = (file, maxWidth, maxHeight, quality = 0.7) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const reader = new FileReader()

    reader.onload = (e) => {
      img.src = e.target.result
    }

    img.onload = () => {
      const canvas = document.createElement('canvas')
      let width = img.width
      let height = img.height

      // Calculate new dimensions while maintaining aspect ratio
      if (width > maxWidth) {
        height = Math.round((height *= maxWidth / width))
        width = maxWidth
      }

      if (height > maxHeight) {
        width = Math.round((width *= maxHeight / height))
        height = maxHeight
      }

      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)

      // Convert canvas to Data URL (Base64)
      const dataUrl = canvas.toDataURL('image/jpeg', quality)
      // Extract base64 data without the Data URL prefix
      const base64Data = dataUrl.split(',')[1]
      resolve({base64Data, mimeType: 'image/jpeg'})
    }

    img.onerror = (error) => reject(error)
    reader.onerror = (error) => reject(error)

    reader.readAsDataURL(file)
  })
}

const UserDropdown = () => {
  const {user, setUser, logout} = useAuth()

  const [state, setState] = useState({
    showModal: false,
    logTime: null,
    error: null,
    userDetail: {
      imageBase64: user?.image ? btoa(new Uint8Array(user.image.data).reduce((data, byte) => data + String.fromCharCode(byte), '')) : null,
      mimeType: user?.image?.mimetype ?? 'image/jpeg',
      name: user?.name ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
      address: user?.address ?? '',
      removeImage: false,
    },
  })

  useEffect(() => {
    const storedLogTime = localStorage.getItem('logTime')
    if (storedLogTime) {
      setState((prevState) => ({...prevState, logTime: storedLogTime}))
    }
  }, [])

  const handleShowModal = () => setState((prevState) => ({...prevState, showModal: true}))
  const handleCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false,
      error: null,
    }))
  }

  // Handle Input Changes (Including Image Selection)
  const handleProfileChange = (event) => {
    const {name, value, files} = event.target

    if (files && files[0]?.size > 7.5 * 1024 * 1024) {
      // ~7.5MB limit
      handleError((message) => setState((prevState) => ({...prevState, error: message})), null, 'Image size should not exceed 7.5MB')
      return
    }

    if (files && files[0]) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif']
      if (!allowedTypes.includes(files[0].type)) {
        handleError(
          (message) => setState((prevState) => ({...prevState, error: message})),
          null,
          'Only JPEG, PNG, and GIF files are allowed.'
        )
        return
      }

      // Compress the image using the provided compressImage function
      compressImage(files[0], 800, 800)
        .then(({base64Data, mimeType}) => {
          setState((prevState) => ({
            ...prevState,
            error: null,
            userDetail: {
              ...prevState.userDetail,
              imageBase64: base64Data,
              mimeType: mimeType,
              removeImage: false,
            },
          }))
        })
        .catch((error) => {
          handleError((message) => setState((prevState) => ({...prevState, error: message})), error, 'Failed to compress image.')
        })
    } else {
      setState((prevState) => ({
        ...prevState,
        error: null,
        userDetail: {
          ...prevState.userDetail,
          [name]: value,
        },
      }))
    }
  }

  // Handle "Remove Image" Action
  const handleRemoveImage = () => {
    setState((prevState) => ({
      ...prevState,
      userDetail: {
        ...prevState.userDetail,
        imageBase64: null,
        mimeType: null,
        removeImage: true,
      },
    }))
  }

  // Handle Saving Profile Changes
  const handleSaveProfile = () => {
    const {userDetail} = state
    const fieldsToUpdate = {}

    // Include changed text fields
    if (userDetail.name !== user?.name) fieldsToUpdate.name = userDetail.name
    if (userDetail.email !== user?.email) fieldsToUpdate.email = userDetail.email
    if (userDetail.phone !== user?.phone) fieldsToUpdate.phone = userDetail.phone
    if (userDetail.address !== user?.address) fieldsToUpdate.address = userDetail.address

    // Include image if changed
    if (userDetail.imageBase64 && userDetail.imageBase64 !== user?.image) {
      fieldsToUpdate.image = userDetail.imageBase64
      fieldsToUpdate.mimeType = userDetail.mimeType
    }

    // Include removeImage flag if image is to be removed
    if (userDetail.removeImage) {
      fieldsToUpdate.removeImage = true
    }

    // Log the JSON payload for debugging
    console.log('Fields to update:', fieldsToUpdate)

    if (user && user.id) {
      updateUser(fieldsToUpdate, user.id)
        .then((updatedUserData) => {
          setUser((prevUser) => ({
            ...prevUser,
            ...updatedUserData,
          }))
          setState((prevState) => ({
            ...prevState,
            showModal: false,
            error: null,
          }))
        })
        .catch((error) => {
          handleError((message) => setState((prevState) => ({...prevState, error: message})), error, 'Update failed.')
        })
    }
  }

  // Calculate Logged-in Time
  const calculateLogTime = () => {
    const {logTime} = state
    if (!logTime) return 'Unknown'
    const now = new Date()
    const logDate = new Date(logTime)
    const diff = Math.floor((now - logDate) / 60000) // Difference in minutes
    if (diff < 60) return `${diff} minutes`
    const hours = Math.floor(diff / 60)
    return `${hours} hours`
  }

  const {showModal, userDetail, error} = state

  // Function to construct Data URL for image display
  const getImageDataUrl = () => {
    if (userDetail.imageBase64 && userDetail.mimeType) {
      return `data:${userDetail.mimeType};base64,${userDetail.imageBase64}`
    } else {
      return 'https://placehold.co/42/000000/FFF?text=Profile'
    }
  }

  return (
    <>
      <Dropdown align='end'>
        <Dropdown.Toggle as='a' className='nav-link dropdown-toggle mt-1' id='navbarDropdown'>
          <img alt='Profile' src={getImageDataUrl()} className='rounded-circle me-2' width='42' height='42' />
          <div className='d-none d-lg-inline-block text-white'>{userDetail.name}</div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header>Logged in {calculateLogTime()} ago</Dropdown.Header>
          {user &&
            user.datas &&
            user.datas.map((data, idata) => (
              <Dropdown.Item key={idata} as={NavLink} to={data.link} className={({isActive}) => (isActive ? 'active' : '')}>
                <i className={data.icode} /> {data.title}
              </Dropdown.Item>
            ))}
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleShowModal}>Edit Profile</Dropdown.Item>
          <Dropdown.Item className='text-danger' onClick={logout}>
            <i className='defaultLogoutIcon' /> Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Alert variant='danger' onClose={() => handleCloseModal()} dismissible>
              {error}
            </Alert>
          )}
          <Form>
            {/* Profile Image Section */}
            <Form.Group className='mb-3'>
              <div className='d-flex justify-content-center'>
                <img src={getImageDataUrl()} alt='Profile' className='mt-2 mb-2 rounded-circle' width='100' height='100' />
              </div>
              <Form.Label>Profile Image</Form.Label>
              <Form.Control type='file' accept='image/*' onChange={handleProfileChange} name='img' />
              {/* "Remove Image" Button */}
              {userDetail.imageBase64 && (
                <div className='mt-2'>
                  <p onClick={handleRemoveImage} className='text-danger' style={{cursor: 'pointer'}}>
                    Remove Image
                  </p>
                </div>
              )}
            </Form.Group>

            {/* Profile Name */}
            <Form.Group className='mb-3'>
              <Form.Label>Profile Name</Form.Label>
              <Form.Control type='text' value={userDetail.name} onChange={handleProfileChange} name='name' />
            </Form.Group>

            {/* Email */}
            <Form.Group className='mb-3'>
              <Form.Label>Email</Form.Label>
              <Form.Control type='email' value={userDetail.email} onChange={handleProfileChange} name='email' />
            </Form.Group>

            {/* Phone */}
            <Form.Group className='mb-3'>
              <Form.Label>Phone</Form.Label>
              <Form.Control type='text' value={userDetail.phone} onChange={handleProfileChange} name='phone' />
            </Form.Group>

            {/* Address */}
            <Form.Group className='mb-3'>
              <Form.Label>Address</Form.Label>
              <Form.Control type='text' value={formatAddress(userDetail.address)} onChange={handleProfileChange} name='address' />
            </Form.Group>

            {/* Save Changes Button */}
            <Form.Group className='d-flex justify-content-end'>
              <Button variant='primary' onClick={handleSaveProfile}>
                Save Changes
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UserDropdown
