import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Modal} from 'react-bootstrap'
import {useAuth} from '../../contexts/authContext'
import {getDocumentsByUserId, fetchSignups} from '../../contexts/service'
import {UserCard, DocumentPreview} from '../utils'

const DashStudent = () => {
  const {user} = useAuth()
  const [signups, setSignups] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [document, setDocument] = useState(null)

  useEffect(() => {
    if (user) {
      console.log(user)
      fetchSignups(setSignups)
    }
  }, [user])

  const fetchData = async () => {
    Promise.all(getDocumentsByUserId(user.id))
      .then((documentData) => {
        if (documentData) {
          setDocument(documentData[0])
        }
      })
      .catch((error) => {
        console.log('No data.')
      })
  }

  const handleShowModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  return (
    <div className='main-content'>
      <section className='section'>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <Link to='/signup-data' className='card card-statistic-2' style={{cursor: 'pointer', textDecoration: 'none', color: 'inherit'}}>
              {/* <div className='card-stats'>
                <div className='card-chart'></div>
              </div> */}
              <div className='card-icon shadow-primary bg-primary'>
                <i className='fas fa-user-graduate'></i>
              </div>
              <div className='card-wrap'>
                <div className='card-header'>
                  <h4>Active Students</h4>
                </div>
                <div className='card-body'>{signups}</div>
              </div>
            </Link>
          </div>
          {/* <DocumentPreview document={document} handleShowModal={handleShowModal} /> */}
        </div>
      </section>
      <div>
        <canvas height='400'></canvas>
      </div>

      {document && (
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{document.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {document.mime_type === 'application/pdf' ? (
              <iframe src={`data:application/pdf;base64,${btoa(document.content)}`} width='100%' height='500px'></iframe>
            ) : (
              <pre>{document.content}</pre>
            )}
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default DashStudent
